import React from 'react';
import BootstrapForm from 'react-bootstrap/Form';
import { Component } from '.';

export const Form = ({ children, ...props }) => {
  return (
    <Component {...props} component={BootstrapForm}>
      {children}
    </Component>
  );
};

Form.Group = BootstrapForm.Group;

Form.displayName = 'Form';
export default Form;
