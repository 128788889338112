import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Text, Group, Input, List, Image } from '../components';
import { useEffect } from 'react';
import transp from '../assets/transp.png';
import { useTranslation } from 'react-i18next';

export const InvitadosView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const invitadosParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( invitadosParams, [])
	const invitadosOnLoaded = (options) => {
		run([{"id":"set1671021368957","function":"set","what":"@property.buscar","value":"@database.auxiliar.emptystring"}], options)
	}
	useEffect(invitadosOnLoaded, [])
	const text1668286373995OnClick = (options) => {
		run([{"id":"browser1668616852488","function":"browser","url":"mailto:(@database.usuarios.(@property.selected).email"}], options)
	}
	const group1668019264737OnClick = (options) => {
		run([{"id":"set1668286581569","function":"set","what":"@property.selected","value":"@element.listainv.selected"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('invitados.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Text
				id="tituloinvitados"
				className="w-100 h-auto align-center position-relative my-30px f-25px" >
				{t('invitados.tituloinvitados')}
			</Text>
			<Group
				id="grupotodo"
				direction="horizontal"
				className="w-fill h-auto position-relative p-30px" >
				<Group
					id="grupolista"
					direction="vertical"
					className="w-40 min-h-550px h-550px border-primary border-top border-bottom border-start border-end border-radius-25px position-relative p-30px" >
					<Input.Text
						id="inputtext1668542836829"
						name="text"
						src="@property.buscar"
					placeholder={t('invitados.inputtext1668542836829.hint')}
					autoComplete="off"
						className="w-auto h-auto border-primary-light border-top border-bottom border-start border-end position-relative mb-10px" />
					<List
						id="listainv"
						src="@database.usuarios"
						filter={{"what":"nombre","starts_with":"@property.buscar","and":{"what":"evento","is":"@cookie.evento","and":{"what":"@property.buscar","is_not":null}},"case_sensitive":false}}
						className="w-100 h-auto position-relative" >
						<Group
							id="group1668019264737"
							onClick={group1668019264737OnClick}
							direction="horizontal"
							className="w-100 h-auto position-relative p-5px" >
							<Image
								id="image1668542727741"
								fallback={transp}
								src="imagen"
								className="w-auto min-h-60px h-60px position-relative" />
							<Text
								id="text1668019280849"
								src="nombre"
								className="w-32 h-auto valign-center position-relative p-10px ms-10px" >
								{t('invitados.text1668019280849')}
							</Text>
						</Group>
					</List>
				</Group>
				<Group
					id="grupodetalle"
					if={{"what":"@property.selected","is_not":null}}
					direction="vertical"
					className="w-100 min-h-550px h-550px border-primary border-top border-bottom border-start border-end border-radius-25px position-relative p-30px ms-30px" >
					<Group
						id="grupodatos"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Image
							id="image1668614735431"
							fallback={transp}
							src="@database.usuarios.(@property.selected).imagen"
							className="w-auto min-h-100px h-100px position-relative" />
						<Group
							id="grouptextdatos"
							direction="vertical"
							className="w-fill h-auto position-relative ms-30px" >
							<Text
								id="text1668285301052"
								className="w-100 h-auto position-relative f-18px" >
								{t('invitados.text1668285301052')}
							</Text>
							<Text
								id="text1668286036525"
								className="w-47 h-auto position-relative f-18px" >
								{t('invitados.text1668286036525')}
							</Text>
							<Text
								id="text1668286373995"
								onClick={text1668286373995OnClick}
								className="w-48 h-auto position-relative f-18px" >
								{t('invitados.text1668286373995')}
							</Text>
						</Group>
					</Group>
					<Group
						id="grrupoalimentacion"
						direction="vertical"
						className="w-100 h-auto border-primary border-top border-bottom border-start border-end border-radius-25px position-relative p-15px mt-20px" >
						<Text
							id="text1668606544905"
							className="w-100 h-auto align-center position-relative f-20px" >
							{t('invitados.text1668606544905')}
						</Text>
						<Group
							id="grrupoalimdetalle"
							direction="horizontal"
							className="w-100 h-auto position-relative mt-10px" >
							<Input.Checkbox
								id="checkboxceliaquia"
								label={t('invitados.checkboxceliaquia.label')}
								name="checkbox"
								src="@database.usuarios.(@property.selected).celiaquia"
								className="w-15 h-auto position-relative" />
							<Input.Checkbox
								id="checkboxvegano"
								label={t('invitados.checkboxvegano.label')}
								name="checkbox"
								src="@database.usuarios.(@property.selected).vegano"
								className="w-15 h-auto position-relative" />
							<Input.Checkbox
								id="checkboxvegano1668607029833"
								label={t('invitados.checkboxvegano1668607029833.label')}
								name="checkbox"
								src="@database.usuarios.(@property.selected).alergias"
								className="w-15 h-auto position-relative" />
							<Text
								id="textespalergias"
								className="w-fill h-auto position-relative f-18px" >
								{t('invitados.textespalergias')}
							</Text>
						</Group>
					</Group>
					<Group
						id="grrupotransporte"
						direction="vertical"
						className="w-100 h-auto border-primary border-top border-bottom border-start border-end border-radius-25px position-relative p-15px mt-20px" >
						<Text
							id="texttrans"
							className="w-100 h-auto align-center position-relative f-20px" >
							{t('invitados.texttrans')}
						</Text>
						<Group
							id="grrupotransdetalle"
							direction="horizontal"
							className="w-100 h-auto position-relative mt-10px" >
							<Text
								id="textparada"
								className="w-auto h-auto position-relative f-18px" >
								{t('invitados.textparada')}
							</Text>
							<Text
								id="textpasajeros"
								className="w-auto h-auto position-relative ms-20px f-18px" >
								{t('invitados.textpasajeros')}
							</Text>
						</Group>
					</Group>
					<Group
						id="grruponinos"
						direction="vertical"
						className="w-100 h-auto border-primary border-top border-bottom border-start border-end border-radius-25px position-relative p-15px mt-20px" >
						<Text
							id="textninos"
							className="w-100 h-auto align-center position-relative f-20px" >
							{t('invitados.textninos')}
						</Text>
						<Group
							id="grruponinosdetalle"
							direction="horizontal"
							className="w-100 h-auto position-relative mt-10px" >
							<Text
								id="textninoscant"
								className="w-auto h-auto position-relative f-18px" >
								{t('invitados.textninoscant')}
							</Text>
							<Text
								id="texttronascant"
								className="w-auto h-auto position-relative ms-20px f-18px" >
								{t('invitados.texttronascant')}
							</Text>
						</Group>
					</Group>
				</Group>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
