import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const MusicpdfView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('musicpdf.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="grrupomusica1684750368514"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grrupolistamusic1684750368514"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textotitulomusic1684750368514"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('musicpdf.textotitulomusic1684750368514')}
					</Text>
					<Group
						id="grrupocabemusic1684750368514"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre1684750368514"
							className="w-33 h-auto position-relative" >
							{t('musicpdf.cabenombre1684750368514')}
						</Text>
						<Text
							id="cabecantante1684750368514"
							className="w-33 h-auto position-relative" >
							{t('musicpdf.cabecantante1684750368514')}
						</Text>
						<Text
							id="cabecomenmusic1684750368514"
							className="w-33 h-auto position-relative" >
							{t('musicpdf.cabecomenmusic1684750368514')}
						</Text>
					</Group>
					<List
						id="listamusic1684750368514"
						src="@firebase.firestore.eventos.(@property.event).musica"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
						<Group
							id="grrupolineamusic1684750368514"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombre1684750368514"
								src="titulo"
								className="w-33 h-auto position-relative" >
								{t('musicpdf.lineanombre1684750368514')}
							</Text>
							<Text
								id="lineacantante1684750368514"
								src="cantante"
								className="w-33 h-auto position-relative" >
								{t('musicpdf.lineacantante1684750368514')}
							</Text>
							<Text
								id="lineacomenmusic1684750368514"
								src="comentario"
								className="w-33 h-auto position-relative" >
								{t('musicpdf.lineacomenmusic1684750368514')}
							</Text>
						</Group>
					</List>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
