import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Form, Input, Button } from '../components';
import icono from '../assets/icono.png';
import { useTranslation } from 'react-i18next';

export const PerfilView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const button1670239541455OnClick = (options) => {
		run([{"id":"add1670501867377","function":"add","what":{"nombre":"@database.usuarios.(@cookie.userid).nombre","Telefono":"@database.usuarios.(@cookie.userid).Telefono","email":"@database.usuarios.(@cookie.userid).email","celiaquia":"@database.usuarios.(@cookie.userid).celiaquia","vegano":"@database.usuarios.(@cookie.userid).vegano","alergias":"@database.usuarios.(@cookie.userid).alergias","espalergias":"@database.usuarios.(@cookie.userid).espalergias"},"to":"@firebase.firestore.usuarios","key":"@cookie.userid"}], options)
	}
	const Formcontacto3OnSubmit = (options) => {
		run([{"function":"add","what":{"nombre":"@property.nombre","Telefono":"@property.telefono"},"to":"@firebase.firestore.usuarios.(@cookie.userid)","key":"@cookie.userid"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('perfil.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="grupoperfil"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grouparriba"
					direction="horizontal"
					className="w-90 h-auto align-center position-relative" >
					<Group
						id="grupoimagenperfil1669394822050"
						direction="horizontal"
						className="w-fill h-auto border-primary border-top border-bottom border-start border-end border-radius-50px align-center valign-start position-relative p-50px mt-20px" >
						<Image
							id="image1669394822050"
							fallback={icono}
							src="@database.usuarios.(@cookie.userid).imagen"
							className="w-auto min-h-250px h-250px position-relative" />
						<Group
							id="grupoinfo1669394822050"
							direction="vertical"
							className="w-33 h-auto position-relative ms-30px" >
							<Text
								id="textinfo1669394822050"
								className="w-100 h-auto align-center position-relative f-20px ff-renner" >
								{t('perfil.textinfo1669394822050')}
							</Text>
							<Form
								id="formcontacto3"
								action="https://target-url"
	 onSubmit={Formcontacto3OnSubmit}
								className="w-auto h-auto position-relative" >
								<Input.Text
									id="formularionombre"
									label={t('perfil.formularionombre.label')}
									name="text"
									src="@database.usuarios.(@cookie.userid).nombre"
								placeholder={t('perfil.formularionombre.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="formulariotelefono"
									label={t('perfil.formulariotelefono.label')}
									name="text"
									src="@database.usuarios.(@cookie.userid).Telefono"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="formularioemail"
									label={t('perfil.formularioemail.label')}
									name="text"
									src="@database.usuarios.(@cookie.userid).email"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
							</Form>
						</Group>
						<Group
							id="grupoalimentacion1669394822050"
							direction="vertical"
							className="w-33 h-auto valign-start position-relative ms-30px" >
							<Text
								id="texttituloalimentacion1669394822050"
								className="w-100 h-auto align-center position-relative mt-20px f-20px ff-renner" >
								{t('perfil.texttituloalimentacion1669394822050')}
							</Text>
							<Form
								id="formalimentacion1669394822050"
								action="https://target-url"
								className="w-auto h-auto position-relative" >
								<Input.Switch
									id="componentceliaco"
									label={t('perfil.componentceliaco.label')}
									name="checkbox"
									src="@database.usuarios.(@cookie.userid).celiaquia"
									className="w-auto h-auto position-relative" />
								<Input.Switch
									id="componentvegano"
									label={t('perfil.componentvegano.label')}
									name="checkbox"
									src="@database.usuarios.(@cookie.userid).vegano"
									className="w-auto h-auto position-relative" />
								<Input.Switch
									id="componentalergias"
									label={t('perfil.componentalergias.label')}
									name="checkbox"
									src="@database.usuarios.(@cookie.userid).alergias"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="componentespalergias1669394822050"
									if={{"what":"@database.usuarios.(@cookie.userid).alergias","is":true}}
									label={t('perfil.componentespalergias1669394822050.label')}
									name="text"
									src="@database.usuarios.(@cookie.userid).espalergias"
								autoComplete="off"
									className="max-w-100 w-auto max-h-100 h-auto position-relative" />
							</Form>
						</Group>
						<Button
							id="button1670239541455"
							onClick={button1670239541455OnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('perfil.button1670239541455')}
						</Button>
					</Group>
				</Group>
				<Group
					id="tituloeventoactual"
					direction="horizontal"
					className="w-auto h-auto align-center position-relative" >
					<Text
						id="textoeventoact"
						className="w-auto h-auto valign-center position-relative ff-renner" >
						{t('perfil.textoeventoact')}
					</Text>
					<Text
						id="texteventocargado"
						src="@cookie.evento"
						className="w-auto h-auto position-relative ms-20px f-25px ff-renner" >
						{t('perfil.texteventocargado')}
					</Text>
				</Group>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
