import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const ContactoDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const contacto_buttons_positiveOnClick = (options) => {
		run([{"function":"add","what":{"nombre":"@property.nombrecontacto","email":"@property.mailcontacto","mensaje":"@property.mensajecontacto","subject":"web, ven a mi boda","body":"nombre:(@property.nombrecontacto)<br>Email:(@property.mailcontacto)<br>Mensaje: (@property.mensajecontacto)"},"to":"@firebase.firestore.emails"},{"function":"goto","view":"home"},{"function":"hide","dialog":"contacto"}], options)
	}

  return (
    
    
			<Modal id="contacto">
					<Modal.Body>
						<Group
							id="grupoformulario1668017407470"
							direction="vertical"
							className="w-auto h-auto align-center position-relative mt-30px" >
							<Text
								id="textoformulario1668017407470"
								className="w-100 h-auto position-relative" >
								{t('contacto.textoformulario1668017407470')}
							</Text>
							<Input.Text
								id="inputnombreformulario1668017407470"
								label={t('contacto.inputnombreformulario1668017407470.label')}
								name="text"
								src="@property.nombrecontacto"
							autoComplete="off"
								className="w-100 h-auto position-relative" />
							<Input.Text
								id="inputmailformulario1668017407470"
								label={t('contacto.inputmailformulario1668017407470.label')}
								name="text"
								src="@property.mailcontacto"
							autoComplete="off"
								className="w-100 h-auto position-relative mt-20px" />
							<Input.TextArea
								id="inputmensajeformulario1668017407470"
								label={t('contacto.inputmensajeformulario1668017407470.label')}
								name="text"
								src="@property.mensajecontacto"
							autoComplete="off"
								className="w-100 h-auto position-relative mt-20px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="contacto_buttons_positive"
							onClick={contacto_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('contacto.contacto_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default ContactoDialog;
