import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const LoginDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const login_buttons_positiveOnClick = (options) => {
		run([{"id":"login1668104805835","function":"login","provider":"firebase.email","pass":"@property.loginpass","email":"@property.loginemail"},{"id":"set1668369293265","function":"set","what":"@cookie.evento","value":"@firebase.firestore.usuarios.(@firebase.user.id).evento"},{"id":"set1668369401033","function":"set","what":"@cookie.codigowp","value":"@firebase.firestore.usuarios.(@firebase.user.id).codigowp"},{"id":"set1668615167373","function":"set","what":"@cookie.userid","value":"@firebase.user.id"},{"id":"goto1668104890139","function":"goto","view":"carga1","if":{"what":"@firebase.firestore.usuarios.(@cookie.userid).admin","is":true,"else":[{"id":"popup1671392504548","function":"popup","dialog":"noadmin"}]}},{"function":"hide","dialog":"login"}], options)
	}

  return (
    
    
			<Modal id="login">
					<Modal.Body>
						<Group
							id="grupologin1668017860878"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1668017860878"
								className="w-auto h-auto align-center position-relative f-20px ff-renner" >
								{t('login.text1668017860878')}
							</Text>
							<Input.Text
								id="inputtext1668017860878"
								label={t('login.inputtext1668017860878.label')}
								name="text"
								src="@property.loginemail"
							autoComplete="off"
								className="w-90 h-auto position-relative mt-25px" />
							<Input.Password
								id="inputtext1668017860878"
								label={t('login.inputtext1668017860878.label')}
								name="text"
								src="@property.loginpass"
							autoComplete="off"
								className="w-90 h-auto position-relative mt-25px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="login_buttons_positive"
							onClick={login_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('login.login_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default LoginDialog;
