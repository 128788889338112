import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const ListadoaliView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const beditdescgaleria1684750142081OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/us-central1-perfiles-2ede2.cloudfunctions.net\/dataToExcel?evento=(@cookie.evento)","name":"invitados.xlsx"}], options)
	}
	const botonpdfaliOnClick = (options) => {
		run([{"id":"api1688408775680","function":"api","api":{"method":"doppio.pdf.sync","url":"https:\/\/venamiboda.net\/alipdf?event=(@cookie.evento)"},"onsuccess":[{"id":"set1688408775680","function":"set","what":"@cookie.pdf","value":"@cookie.respuesta.documentUrl"},{"id":"download1688408775680","function":"download","url":"@cookie.pdf","name":"listadobus.pdf"}],"into":"@cookie.respuesta"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('listadoali.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="grupolistaali"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grupolistaalimentacion"
					direction="vertical"
					className="w-50 h-auto position-relative" >
					<Group
						id="grrupoceliaco"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="texttituloceli"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('listadoali.texttituloceli')}
						</Text>
						<Group
							id="grrupocabeceraceli"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombreceli"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabenombreceli')}
							</Text>
							<Text
								id="cabeTelefonoceli"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabeTelefonoceli')}
							</Text>
						</Group>
						<List
							id="listaceli"
							src="@database.usuarios"
							filter={{"what":"celiaquia","is":true,"and":{"what":"evento","is":"@cookie.evento"}}}
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Group
								id="grupolineaceli"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombreceli"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineanombreceli')}
								</Text>
								<Text
									id="lineaTelefonoceli"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineaTelefonoceli')}
								</Text>
							</Group>
						</List>
					</Group>
					<Group
						id="grupovegano"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="textotitulovegano"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('listadoali.textotitulovegano')}
						</Text>
						<Group
							id="grrupocabevegano"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombrevega"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabenombrevega')}
							</Text>
							<Text
								id="cabeTelefonovega"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabeTelefonovega')}
							</Text>
						</Group>
						<List
							id="listavegano"
							src="@database.usuarios"
							filter={{"what":"vegano","is":true,"and":{"what":"evento","is":"@cookie.evento"}}}
							className="w-100 h-auto position-relative" >
							<Group
								id="grupolineavega"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombrevega"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineanombrevega')}
								</Text>
								<Text
									id="lineaTelefonovega"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineaTelefonovega')}
								</Text>
							</Group>
						</List>
					</Group>
					<Group
						id="grrupoalergias"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="texttituloale"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('listadoali.texttituloale')}
						</Text>
						<Group
							id="grrupocabeale"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombreale"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabenombreale')}
							</Text>
							<Text
								id="cabeTelefonoale"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabeTelefonoale')}
							</Text>
							<Text
								id="cabeespale"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabeespale')}
							</Text>
						</Group>
						<List
							id="listaale"
							src="@database.usuarios"
							filter={{"what":"alergias","is":true,"and":{"what":"evento","is":"@cookie.evento"}}}
							className="w-100 h-auto position-relative" >
							<Group
								id="grrupolineaale"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombreale"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineanombreale')}
								</Text>
								<Text
									id="lineaTelefonoale"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineaTelefonoale')}
								</Text>
								<Text
									id="lineaespale"
									src="espalergias"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineaespale')}
								</Text>
							</Group>
						</List>
					</Group>
					<Group
						id="grrupotrona"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="texttitulotrona"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('listadoali.texttitulotrona')}
						</Text>
						<Group
							id="grrupocabeale1684751248458"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombreale1684751248458"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabenombreale1684751248458')}
							</Text>
							<Text
								id="cabeTelefonoale1684751248458"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabeTelefonoale1684751248458')}
							</Text>
							<Text
								id="cabeespale1684751248458"
								className="w-33 h-auto position-relative" >
								{t('listadoali.cabeespale1684751248458')}
							</Text>
						</Group>
						<List
							id="listaale1684751248458"
							src="@database.usuarios"
							filter={{"what":"ninostrona","is":true,"and":{"what":"evento","is":"@cookie.evento"}}}
							className="w-100 h-auto position-relative" >
							<Group
								id="grrupolineaale1684751248458"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombreale1684751248458"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineanombreale1684751248458')}
								</Text>
								<Text
									id="lineaTelefonoale1684751248458"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineaTelefonoale1684751248458')}
								</Text>
								<Text
									id="lineaespale1684751248458"
									src="tronascant"
									className="w-33 h-auto position-relative" >
									{t('listadoali.lineaespale1684751248458')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="groupexport1684750142081"
					direction="horizontal"
					className="w-auto h-auto align-end valign-start position-absolute m-30px" >
					<Text
						id="botonpdfali"
						onClick={botonpdfaliOnClick}
						className="min-w-0px w-auto h-auto border-radius-10px align-end valign-start position-relative bg-primary p-10px me-15px text-white f-20px" >
						{t('listadoali.botonpdfali')}
					</Text>
					<Text
						id="beditdescgaleria1684750142081"
						onClick={beditdescgaleria1684750142081OnClick}
						className="w-auto h-auto border-radius-10px align-end valign-start position-relative bg-primary p-10px text-white f-20px" >
						{t('listadoali.beditdescgaleria1684750142081')}
					</Text>
				</Group>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
