import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const AlipdfView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('alipdf.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="grupolistaali1684749208812"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grupolistaalimentacion1684749208812"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="grrupoceliaco1684749208812"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="texttituloceli1684749208812"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('alipdf.texttituloceli1684749208812')}
						</Text>
						<Group
							id="grrupocabeceraceli1684749208812"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombreceli1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabenombreceli1684749208812')}
							</Text>
							<Text
								id="cabeTelefonoceli1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabeTelefonoceli1684749208812')}
							</Text>
						</Group>
						<List
							id="listaceli"
							src="@firebase.firestore.usuarios"
							filter={{"what":"celiaquia","is":true,"and":{"what":"evento","is":"@property.event"}}}
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Group
								id="grupolineaceli1684749208812"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombreceli1684749208812"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineanombreceli1684749208812')}
								</Text>
								<Text
									id="lineaTelefonoceli1684749208812"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineaTelefonoceli1684749208812')}
								</Text>
							</Group>
						</List>
					</Group>
					<Group
						id="grupovegano1684749208812"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="textotitulovegano1684749208812"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('alipdf.textotitulovegano1684749208812')}
						</Text>
						<Group
							id="grrupocabevegano1684749208812"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombrevega1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabenombrevega1684749208812')}
							</Text>
							<Text
								id="cabeTelefonovega1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabeTelefonovega1684749208812')}
							</Text>
						</Group>
						<List
							id="listavega"
							src="@firebase.firestore.usuarios"
							filter={{"what":"vegano","is":true,"and":{"what":"evento","is":"@property.event"}}}
							className="w-100 h-auto position-relative" >
							<Group
								id="grupolineavega1684749208812"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombrevega1684749208812"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineanombrevega1684749208812')}
								</Text>
								<Text
									id="lineaTelefonovega1684749208812"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineaTelefonovega1684749208812')}
								</Text>
							</Group>
						</List>
					</Group>
					<Group
						id="grrupoalergias1684749208812"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="texttituloale1684749208812"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('alipdf.texttituloale1684749208812')}
						</Text>
						<Group
							id="grrupocabeale1684749208812"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombreale1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabenombreale1684749208812')}
							</Text>
							<Text
								id="cabeTelefonoale1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabeTelefonoale1684749208812')}
							</Text>
							<Text
								id="cabeespale1684749208812"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabeespale1684749208812')}
							</Text>
						</Group>
						<List
							id="listaalerg"
							src="@firebase.firestore.usuarios"
							filter={{"what":"alergias","is":true,"and":{"what":"evento","is":"@property.event"}}}
							className="w-100 h-auto position-relative" >
							<Group
								id="grrupolineaale1684749208812"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombreale1684749208812"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineanombreale1684749208812')}
								</Text>
								<Text
									id="lineaTelefonoale1684749208812"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineaTelefonoale1684749208812')}
								</Text>
								<Text
									id="lineaespale1684749208812"
									src="espalergias"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineaespale1684749208812')}
								</Text>
							</Group>
						</List>
					</Group>
					<Group
						id="grrupotrona1688553577158"
						direction="vertical"
						className="w-70 h-auto align-center position-relative mt-20px" >
						<Text
							id="texttitulotrona1688553577158"
							className="w-auto h-auto align-center position-relative f-25px" >
							{t('alipdf.texttitulotrona1688553577158')}
						</Text>
						<Group
							id="grrupocabeale1688553577158"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
							<Text
								id="cabenombreale1688553577158"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabenombreale1688553577158')}
							</Text>
							<Text
								id="cabeTelefonoale1688553577158"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabeTelefonoale1688553577158')}
							</Text>
							<Text
								id="cabeespale1688553577158"
								className="w-33 h-auto position-relative" >
								{t('alipdf.cabeespale1688553577158')}
							</Text>
						</Group>
						<List
							id="listaale1688553577158"
							src="@firebase.firestore.usuarios"
							filter={{"what":"ninostrona","is":true,"and":{"what":"evento","is":"@property.event"}}}
							className="w-100 h-auto position-relative" >
							<Group
								id="grrupolineaale1688553577158"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
								<Text
									id="lineanombreale1688553577158"
									src="nombre"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineanombreale1688553577158')}
								</Text>
								<Text
									id="lineaTelefonoale1688553577158"
									src="Telefono"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineaTelefonoale1688553577158')}
								</Text>
								<Text
									id="lineaespale1688553577158"
									src="tronascant"
									className="w-33 h-auto position-relative" >
									{t('alipdf.lineaespale1688553577158')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
