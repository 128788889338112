import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Image } from '../components';
import icon_512 from '../assets/icon/icon_512.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const AreaclienteWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const AreaclientewpItemsSalirOnClick = (options) => {
		run([{"id":"logout1671456306157","function":"logout"},{"id":"goto1671456314727","function":"goto","view":"home"}], options)
	}
	const AreaclientewpItemsListadosItemsMusicaOnClick = (options) => {
		run([{"id":"goto1671365390504","function":"goto","view":"listadomusic"}], options)
	}
	const AreaclientewpItemsListadosItemsAlimentacionOnClick = (options) => {
		run([{"id":"goto1671364536707","function":"goto","view":"listadoali"}], options)
	}
	const AreaclientewpItemsListadosItemsBusOnClick = (options) => {
		run([{"id":"goto1671364495794","function":"goto","view":"listadobus"}], options)
	}
	const AreaclientewpItemsGaleriaOnClick = (options) => {
		run([{"id":"goto1669554958764","function":"goto","view":"galeria"}], options)
	}
	const AreaclientewpItemsConfiguracionOnClick = (options) => {
		run([{"id":"goto1669554898036","function":"goto","view":"configuracion"}], options)
	}
	const AreaclientewpItemsInvitadosOnClick = (options) => {
		run([{"id":"goto1669554864064","function":"goto","view":"invitados"}], options)
	}
	const AreaclientewpItemsEventoOnClick = (options) => {
		run([{"id":"goto1669554829765","function":"goto","view":"evento"}], options)
	}
	const AreaclientewpItemsPerfilOnClick = (options) => {
		run([{"id":"goto1669554799990","function":"goto","view":"perfil"}], options)
	}

  return (
    <main id="AreaclienteWrapper" className="view h-fill">
        
				<Navbar bg="primary" expand="lg" sticky="top" className="z-index-10">
					<Container>
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="areacliente_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('areaclientewp.items.perfil.title')} onClick={AreaclientewpItemsPerfilOnClick} href="perfil" />
							<Nav.Item title={t('areaclientewp.items.evento.title')} onClick={AreaclientewpItemsEventoOnClick} href="evento" />
							<Nav.Item title={t('areaclientewp.items.invitados.title')} onClick={AreaclientewpItemsInvitadosOnClick} href="invitados" />
							<Nav.Item title={t('areaclientewp.items.configuracion.title')} onClick={AreaclientewpItemsConfiguracionOnClick} href="configuracion" />
							<Nav.Item title={t('areaclientewp.items.galeria.title')} onClick={AreaclientewpItemsGaleriaOnClick} href="galeria" />
							<Nav.Item title={t('areaclientewp.items.listados.title')}>
								<Nav.Item title={t('areaclientewp.items.listados.items.bus.title')} onClick={AreaclientewpItemsListadosItemsBusOnClick} href="listadobus" />
								<Nav.Item title={t('areaclientewp.items.listados.items.alimentacion.title')} onClick={AreaclientewpItemsListadosItemsAlimentacionOnClick} href="listadoali" />
								<Nav.Item title={t('areaclientewp.items.listados.items.musica.title')} onClick={AreaclientewpItemsListadosItemsMusicaOnClick} href="listadomusic" />
							</Nav.Item>
							<Nav.Item title={t('areaclientewp.items.salir.title')} onClick={AreaclientewpItemsSalirOnClick} href="home" />
						</Nav>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="areacliente_actionbar_logo"
									src={icon_512}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="areacliente_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('areaclientewp.items.perfil.title')} onClick={AreaclientewpItemsPerfilOnClick} href="perfil" />
							<Nav.Item title={t('areaclientewp.items.evento.title')} onClick={AreaclientewpItemsEventoOnClick} href="evento" />
							<Nav.Item title={t('areaclientewp.items.invitados.title')} onClick={AreaclientewpItemsInvitadosOnClick} href="invitados" />
							<Nav.Item title={t('areaclientewp.items.configuracion.title')} onClick={AreaclientewpItemsConfiguracionOnClick} href="configuracion" />
							<Nav.Item title={t('areaclientewp.items.galeria.title')} onClick={AreaclientewpItemsGaleriaOnClick} href="galeria" />
							<Nav.Item title={t('areaclientewp.items.listados.title')}>
								<Nav.Item title={t('areaclientewp.items.listados.items.bus.title')} onClick={AreaclientewpItemsListadosItemsBusOnClick} href="listadobus" />
								<Nav.Item title={t('areaclientewp.items.listados.items.alimentacion.title')} onClick={AreaclientewpItemsListadosItemsAlimentacionOnClick} href="listadoali" />
								<Nav.Item title={t('areaclientewp.items.listados.items.musica.title')} onClick={AreaclientewpItemsListadosItemsMusicaOnClick} href="listadomusic" />
							</Nav.Item>
							<Nav.Item title={t('areaclientewp.items.salir.title')} onClick={AreaclientewpItemsSalirOnClick} href="home" />
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
					</Container>
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
