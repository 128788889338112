import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const ListadobusView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const beditdescgaleria1684749496394OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/us-central1-perfiles-2ede2.cloudfunctions.net\/databus?evento=(@cookie.evento)","name":"transporte.xlsx"}], options)
	}
	const botonpdfOnClick = (options) => {
		run([{"id":"api1684322933947","function":"api","api":{"method":"doppio.pdf.sync","url":"https:\/\/venamiboda.net\/buspdf?event=(@cookie.evento)"},"onsuccess":[{"id":"set1684323483400","function":"set","what":"@cookie.pdf","value":"@cookie.respuesta.documentUrl"},{"id":"download1684323065757","function":"download","url":"@cookie.pdf","name":"listadobus.pdf"}],"into":"@cookie.respuesta"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('listadobus.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="grupolistadobus"
				direction="vertical"
				className="w-50 h-auto position-relative" >
				<Group
					id="grrupo1parada"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textoparada1"
						src="@database.eventos.(@cookie.evento).parada1"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('listadobus.textoparada1')}
					</Text>
					<Group
						id="grrupop1cabecera"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabenombre')}
						</Text>
						<Text
							id="cabeTelefono"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabeTelefono')}
						</Text>
						<Text
							id="cabepasajeros"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabepasajeros')}
						</Text>
					</Group>
					<List
						id="listabus"
						src="@database.usuarios"
						filter={{"what":"parada","is":"@database.eventos.(@cookie.evento).parada1"}}
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
						<Group
							id="grrupop1cabecera1671310526842"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombre"
								src="nombre"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineanombre')}
							</Text>
							<Text
								id="lineaTelefono"
								src="Telefono"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineaTelefono')}
							</Text>
							<Text
								id="lineapasajeros"
								src="pasajeros"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineapasajeros')}
							</Text>
						</Group>
					</List>
				</Group>
				<Group
					id="grrupo2parada"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textoparada2"
						src="@database.eventos.(@cookie.evento).parada2"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('listadobus.textoparada2')}
					</Text>
					<Group
						id="grrupop2cabecera"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre1671312909779"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabenombre1671312909779')}
						</Text>
						<Text
							id="cabeTelefono1671312909779"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabeTelefono1671312909779')}
						</Text>
						<Text
							id="cabepasajeros1671312909779"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabepasajeros1671312909779')}
						</Text>
					</Group>
					<List
						id="listabusp2"
						src="@database.usuarios"
						filter={{"what":"parada","is":"@database.eventos.(@cookie.evento).parada2"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="grrupop1cabecera1671312909779"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombre1671312909779"
								src="nombre"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineanombre1671312909779')}
							</Text>
							<Text
								id="lineaTelefono1671312909779"
								src="Telefono"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineaTelefono1671312909779')}
							</Text>
							<Text
								id="lineapasajeros1671312909779"
								src="pasajeros"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineapasajeros1671312909779')}
							</Text>
						</Group>
					</List>
				</Group>
				<Group
					id="grrupo3parada"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textoparada3"
						src="@database.eventos.(@cookie.evento).parada3"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('listadobus.textoparada3')}
					</Text>
					<Group
						id="grrupop3cabecera"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre1671323266277"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabenombre1671323266277')}
						</Text>
						<Text
							id="cabeTelefono1671323266277"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabeTelefono1671323266277')}
						</Text>
						<Text
							id="cabepasajeros1671323266277"
							className="w-33 h-auto position-relative" >
							{t('listadobus.cabepasajeros1671323266277')}
						</Text>
					</Group>
					<List
						id="listabusp3"
						src="@database.usuarios"
						filter={{"what":"parada","is":"@database.eventos.(@cookie.evento).parada3"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="grrupop3lista"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombrep3"
								src="nombre"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineanombrep3')}
							</Text>
							<Text
								id="lineaTelefono3"
								src="Telefono"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineaTelefono3')}
							</Text>
							<Text
								id="lineapasajeros3"
								src="pasajeros"
								className="w-33 h-auto position-relative" >
								{t('listadobus.lineapasajeros3')}
							</Text>
						</Group>
					</List>
				</Group>
			</Group>
			<Group
				id="groupexport"
				direction="horizontal"
				className="w-auto h-auto align-end valign-start position-absolute m-30px" >
				<Text
					id="botonpdf"
					onClick={botonpdfOnClick}
					className="min-w-0px w-auto h-auto border-radius-10px align-end valign-start position-relative bg-primary p-10px me-15px text-white f-20px" >
					{t('listadobus.botonpdf')}
				</Text>
				<Text
					id="beditdescgaleria1684749496394"
					onClick={beditdescgaleria1684749496394OnClick}
					className="w-auto h-auto border-radius-10px align-end valign-start position-relative bg-primary p-10px text-white f-20px" >
					{t('listadobus.beditdescgaleria1684749496394')}
				</Text>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
