import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const NoadminDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const noadmin_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"noadmin"}], options)
	}

  return (
    
    
			<Modal id="noadmin">
					<Modal.Body>
						<Text
							id="text1671392341707"
							className="w-100 h-auto position-relative" >
							{t('noadmin.text1671392341707')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="noadmin_buttons_positive"
							onClick={noadmin_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('noadmin.noadmin_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default NoadminDialog;
