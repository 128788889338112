import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Button } from '../components';
import appstore from '../assets/templates/appstore.svg';
import googleplaybadge_cd66b4b670213ea2993bd4eecbb365b3 from '../assets/templates/googleplaybadge_cd66b4b670213ea2993bd4eecbb365b3.png';
import separator_white from '../assets/templates/separator_white.svg';
import regalo from '../assets/regalo.svg';
import contacta from '../assets/contacta.svg';
import features_3 from '../assets/templates/features_3.svg';
import features_2 from '../assets/templates/features_2.svg';
import features_1 from '../assets/templates/features_1.svg';
import feature_4 from '../assets/feature_4.svg';
import feature_3 from '../assets/templates/feature_3.svg';
import feature_2 from '../assets/feature_2.svg';
import feature_1 from '../assets/feature_1.svg';
import separator from '../assets/templates/separator.svg';
import samsung_frame from '../assets/templates/samsung_frame.png';
import template_screenshot6c67ec71_790f05925f7341d92d19ecbd1a40a6ef from '../assets/template_screenshot6c67ec71_790f05925f7341d92d19ecbd1a40a6ef.png';
import triangle from '../assets/templates/triangle.png';
import imagen_banner from '../assets/imagen_banner.jpg';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeDownloadBadge1668016618728OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/play.google.com\/store\/games?hl=en&gl=US","target":"_doyoLink"}], options)
	}
	const buttoncliente1668016618728OnClick = (options) => {
		run([{"function":"popup","dialog":"login"}], options)
	}
	const buttoncontacto1668016618728OnClick = (options) => {
		run([{"function":"popup","dialog":"contacto"}], options)
	}
	const homeBannerInfoMoreButton1668016618728OnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeFeatures"}], options)
	}
	const homeBannerInfoGetButton1668016618728OnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeDownload"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="authBody1668016618728"
				direction="vertical"
				className="w-100 h-fill position-relative scrollable" >
				<Group
					id="homeBanner1668016618728"
					direction="horizontal"
					className="max-w-100 w-100 h-auto position-relative" >
					<Image
						id="homeBannerImage1668016618728"
						src={imagen_banner}
						className="w-100 min-h-100 h-100 position-absolute cover" />
					<Group
						id="homeBannerDarken1668016618728"
						direction="auto"
						className="w-100 min-h-100 h-100 position-absolute bg-template_darken" >
					</Group>
					<Image
						id="homeBannerImage1668016618728"
						src={triangle}
						className="w-100 min-h-200px h-200px valign-end position-absolute-bottom mb--1px fill" />
					<Group
						id="homeBannerContent1668016618728"
						direction="auto"
						className="w-100 min-h-103vh h-auto position-relative" >
						<Group
							id="homeBannerInfoContent1668016618728"
							direction="vertical"
							className="min-w-300px w-fill h-auto align-start valign-center position-relative ps-10 pe-40 py-48px" >
							<Text
								id="homeBannerInfoTitle1668016618728"
								tag="h1"
								className="w-100 h-auto position-relative text-text-on-accent ff-home" >
								{t('home.homeBannerInfoTitle1668016618728')}
							</Text>
							<Text
								id="homeBannerInfoDescription1668016618728"
								className="w-100 h-auto position-relative text-text-on-accent ff-home" >
								{t('home.homeBannerInfoDescription1668016618728')}
							</Text>
							<Group
								id="homeBannerInfoButtons1668016618728"
								direction="auto"
								className="w-100 h-auto position-relative mt-32px" >
								<Button
									id="homeBannerInfoGetButton1668016618728"
									onClick={homeBannerInfoGetButton1668016618728OnClick}
									variant="text-on-primary"
									className="w-auto h-auto border-radius-50px position-relative me-16px mb-16px" >
									{t('home.homeBannerInfoGetButton1668016618728')}
								</Button>
								<Button
									id="homeBannerInfoMoreButton1668016618728"
									onClick={homeBannerInfoMoreButton1668016618728OnClick}
									variant="primary-light"
									className="w-auto h-auto border-radius-50px position-relative mb-16px" >
									{t('home.homeBannerInfoMoreButton1668016618728')}
								</Button>
							</Group>
						</Group>
						<Group
							id="homeBannerPhone1668016618728"
							direction="horizontal"
							className="max-w-500px min-w-300px w-25 h-auto align-end valign-end position-relative pe-8 mb-64px" >
							<Group
								id="phoneScreenshotWrapper1668016618728"
								direction="vertical"
								className="w-320px h-auto border-radius-30px position-absolute p-1" >
								<Image
									id="phoneScreenshot1668016618728"
									src={template_screenshot6c67ec71_790f05925f7341d92d19ecbd1a40a6ef}
									className="w-100 h-auto border-radius-30px position-relative" />
							</Group>
							<Image
								id="phoneFrame1668016618728"
								src={samsung_frame}
								className="w-320px h-fill position-relative" />
						</Group>
						<Group
							id="grupobotones1668016618728"
							direction="horizontal"
							className="w-100 h-auto align-end valign-start position-absolute m-50px" >
							<Button
								id="buttoncontacto1668016618728"
								onClick={buttoncontacto1668016618728OnClick}
								variant="primary"
								className="w-auto h-auto border-radius-50px align-end valign-start position-relative" >
								{t('home.buttoncontacto1668016618728')}
							</Button>
							<Button
								id="buttoncliente1668016618728"
								onClick={buttoncliente1668016618728OnClick}
								variant="primary"
								className="w-auto h-auto border-radius-50px align-end valign-start position-relative ms-30px" >
								{t('home.buttoncliente1668016618728')}
							</Button>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeOverview1668016618728"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
					<Text
						id="homeOverviewTitle1668016618728"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home.homeOverviewTitle1668016618728')}
					</Text>
					<Image
						id="homeOverviewSeparator1668016618728"
						src={separator}
						className="w-82px h-auto align-center position-relative mb-32px" />
					<Text
						id="homeOverviewDescription1668016618728"
						className="w-70 h-auto align-center position-relative ff-home text-center" >
						{t('home.homeOverviewDescription1668016618728')}
					</Text>
				</Group>
				<Group
					id="homeHighlights1668016618728"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px mt-48px" >
					<Text
						id="homeOverviewTitle1668016618728"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home.homeOverviewTitle1668016618728')}
					</Text>
					<Group
						id="homeHighlightsWrapper1668016618728"
						direction="auto"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="homeHighlightsGroup1668016618728"
							direction="vertical"
							className="min-w-250px w-fill h-auto position-relative p-16px" >
							<Image
								id="homeHighlightsGroup1Icon1668016618728"
								src={feature_1}
								className="max-w-200px w-50 h-auto align-center position-relative" />
							<Group
								id="homeHighlightsSeparator1668016618728"
								direction="horizontal"
								className="w-30 min-h-2px h-2px align-center position-relative bg-primary-light-to-primary-dark m-32px" >
							</Group>
							<Text
								id="homeHighlightsGroup1Title"
								tag="h2"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary ff-home text-center" >
								{t('home.homeHighlightsGroup1Title')}
							</Text>
							<Text
								id="homeHighlightsGroup2Description"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary-text ff-home text-center" >
								{t('home.homeHighlightsGroup2Description')}
							</Text>
						</Group>
						<Group
							id="homeHighlightsGroup1668016618728"
							direction="vertical"
							className="min-w-250px w-fill h-auto position-relative p-16px" >
							<Image
								id="homeHighlightsGroup2Icon1668016618728"
								src={feature_2}
								className="max-w-200px w-50 h-auto align-center position-relative" />
							<Group
								id="homeHighlightsSeparator1668016618728"
								direction="horizontal"
								className="w-30 min-h-2px h-2px align-center position-relative bg-primary-light-to-primary-dark m-32px" >
							</Group>
							<Text
								id="homeHighlightsGroup4Title"
								tag="h2"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary ff-home text-center" >
								{t('home.homeHighlightsGroup4Title')}
							</Text>
							<Text
								id="homeHighlightsGroup1Description"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary-text ff-home text-center" >
								{t('home.homeHighlightsGroup1Description')}
							</Text>
						</Group>
						<Group
							id="homeHighlightsGroup1668016618728"
							direction="vertical"
							className="min-w-250px w-fill h-auto position-relative p-16px" >
							<Image
								id="homeHighlightsGroup3Icon1668016618728"
								src={feature_3}
								className="max-w-200px w-50 h-auto align-center position-relative" />
							<Group
								id="homeHighlightsSeparator1668016618728"
								direction="horizontal"
								className="w-30 min-h-2px h-2px align-center position-relative bg-primary-light-to-primary-dark m-32px" >
							</Group>
							<Text
								id="homeHighlightsGroup3Title1668016618728"
								tag="h2"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary ff-home text-center" >
								{t('home.homeHighlightsGroup3Title1668016618728')}
							</Text>
							<Text
								id="homeHighlightsGroup3Description1668016618728"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary-text ff-home text-center" >
								{t('home.homeHighlightsGroup3Description1668016618728')}
							</Text>
						</Group>
						<Group
							id="homeHighlightsGroup1668016618728"
							direction="vertical"
							className="min-w-250px w-fill h-auto position-relative p-16px" >
							<Image
								id="homeHighlightsGroup1Icon1668016618728"
								src={feature_4}
								className="max-w-200px w-50 h-auto align-center position-relative" />
							<Group
								id="homeHighlightsSeparator1668016618728"
								direction="horizontal"
								className="w-30 min-h-2px h-2px align-center position-relative bg-primary-light-to-primary-dark m-32px" >
							</Group>
							<Text
								id="homeHighlightsGroup2Title"
								tag="h2"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary ff-home text-center" >
								{t('home.homeHighlightsGroup2Title')}
							</Text>
							<Text
								id="homeHighlightsGroup1Description1668016618728"
								className="max-w-400px w-auto h-auto align-center position-relative m-12px text-primary-text ff-home text-center" >
								{t('home.homeHighlightsGroup1Description1668016618728')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFeatures1"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
					<Text
						id="homeFeaturesTitle1"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home.homeFeaturesTitle1')}
					</Text>
					<Image
						id="homeFeaturesSeparator1668016618728"
						src={separator}
						className="w-82px h-auto align-center position-relative mb-32px" />
					<Text
						id="homeFeaturesDescription11"
						className="w-70 h-auto align-center position-relative ff-home text-center" >
						{t('home.homeFeaturesDescription11')}
					</Text>
					<Group
						id="homeFeaturesWrapper1"
						direction="auto"
						className="w-100 h-auto position-relative m-32px" >
						<Group
							id="homeFeatureGroup1"
							direction="horizontal"
							className="min-w-300px w-fill h-auto position-relative p-24px" >
							<Image
								id="homeFeatureIcon1668016618728"
								src={features_1}
								className="w-32px h-auto align-center position-relative" />
							<Group
								id="homeFeaturesInfo77"
								direction="vertical"
								className="w-fill h-auto position-relative ms-16px" >
								<Text
									id="homeFeaturesTitle1"
									tag="h3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary ff-home text-center" >
									{t('home.homeFeaturesTitle1')}
								</Text>
								<Text
									id="homeFeaturesDescription1"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary-text ff-home text-start" >
									{t('home.homeFeaturesDescription1')}
								</Text>
							</Group>
						</Group>
						<Group
							id="homeFeatureGroup2"
							direction="horizontal"
							className="min-w-300px w-fill h-auto position-relative p-24px" >
							<Image
								id="homeFeatureIcon1668016618728"
								src={features_2}
								className="w-32px h-auto align-center position-relative" />
							<Group
								id="homeFeaturesInfo6"
								direction="vertical"
								className="w-fill h-auto position-relative ms-16px" >
								<Text
									id="homeFeaturesTitle2"
									tag="h3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary ff-home text-center" >
									{t('home.homeFeaturesTitle2')}
								</Text>
								<Text
									id="homeFeaturesDescription2"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary-text ff-home text-start" >
									{t('home.homeFeaturesDescription2')}
								</Text>
							</Group>
						</Group>
						<Group
							id="homeFeatureGroup3"
							direction="horizontal"
							className="min-w-300px w-fill h-auto position-relative p-24px" >
							<Image
								id="homeFeatureIcon1668016618728"
								src={features_3}
								className="w-32px h-auto align-center position-relative" />
							<Group
								id="homeFeaturesInfo3"
								direction="vertical"
								className="w-fill h-auto position-relative ms-16px" >
								<Text
									id="homeFeaturesTitle3"
									tag="h3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary ff-home text-center" >
									{t('home.homeFeaturesTitle3')}
								</Text>
								<Text
									id="homeFeaturesDescription3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary-text ff-home text-start" >
									{t('home.homeFeaturesDescription3')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="homeFeaturesWrapper2"
						direction="auto"
						className="w-100 h-auto position-relative m-32px" >
						<Group
							id="homeFeatureGroup4"
							direction="horizontal"
							className="min-w-300px w-fill h-auto position-relative p-24px" >
							<Image
								id="homeFeatureIcon1668016618728"
								src={contacta}
								className="w-32px h-auto align-center position-relative" />
							<Group
								id="homeFeaturesInfo4"
								direction="vertical"
								className="w-fill h-auto position-relative ms-16px" >
								<Text
									id="homeFeaturesTitle4"
									tag="h3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary ff-home text-center" >
									{t('home.homeFeaturesTitle4')}
								</Text>
								<Text
									id="homeFeaturesDescription4"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary-text ff-home text-start" >
									{t('home.homeFeaturesDescription4')}
								</Text>
							</Group>
						</Group>
						<Group
							id="homeFeatureGroup5"
							direction="horizontal"
							className="min-w-300px w-fill h-auto position-relative p-24px" >
							<Image
								id="homeFeatureIcon1668016618728"
								src={contacta}
								className="w-32px h-auto align-center position-relative" />
							<Group
								id="homeFeaturesInfo5"
								direction="vertical"
								className="w-fill h-auto position-relative ms-16px" >
								<Text
									id="homeFeaturesTitle5"
									tag="h3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary ff-home text-center" >
									{t('home.homeFeaturesTitle5')}
								</Text>
								<Text
									id="homeFeaturesDescription5"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary-text ff-home text-start" >
									{t('home.homeFeaturesDescription5')}
								</Text>
							</Group>
						</Group>
						<Group
							id="homeFeatureGroup6"
							direction="horizontal"
							className="min-w-300px w-fill h-auto position-relative p-24px" >
							<Image
								id="homeFeatureIcon1668016618728"
								src={regalo}
								className="w-32px h-auto align-center position-relative" />
							<Group
								id="homeFeaturesInfo6"
								direction="vertical"
								className="w-fill h-auto position-relative ms-16px" >
								<Text
									id="homeFeaturesTitle6"
									tag="h3"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary ff-home text-center" >
									{t('home.homeFeaturesTitle6')}
								</Text>
								<Text
									id="homeFeaturesDescription6"
									className="max-w-400px w-auto h-auto align-start position-relative text-primary-text ff-home text-start" >
									{t('home.homeFeaturesDescription6')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeDownload1668016618728"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-64px" >
					<Group
						id="homeDownloadContentGroup1668016618728"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary-text p-32px" >
						<Text
							id="homeDownloadTitle1668016618728"
							tag="h1"
							className="w-auto h-auto align-center position-relative text-text-on-accent ff-home text-center" >
							{t('home.homeDownloadTitle1668016618728')}
						</Text>
						<Image
							id="homeDownloadSeparator1668016618728"
							src={separator_white}
							className="w-72px h-auto align-center position-relative mt-16px mb-32px" />
						<Text
							id="homeDownloadDescription1668016618728"
							className="w-auto h-auto align-center position-relative m-24px text-secondary-text ff-home text-center" >
							{t('home.homeDownloadDescription1668016618728')}
						</Text>
						<Group
							id="homeDownloadStoresGroup1668016618728"
							direction="horizontal"
							className="w-auto h-auto align-center position-relative mb-32px" >
							<Group
								id="homeDownloadStore1668016618728"
								direction="horizontal"
								className="w-auto min-h-80px h-80px align-center position-relative p-16px" >
								<Image
									id="homeDownloadBadge1668016618728"
									onClick={homeDownloadBadge1668016618728OnClick}
									src={googleplaybadge_cd66b4b670213ea2993bd4eecbb365b3}
									className="w-auto min-h-62px h-62px align-center valign-center position-relative" />
							</Group>
							<Group
								id="homeDownloadStore1668016618728"
								direction="horizontal"
								className="w-auto min-h-80px h-80px align-center position-relative p-16px" >
								<Image
									id="homeDownloadBadge1668016618728"
									onClick={homeDownloadBadge1668016618728OnClick}
									src={appstore}
									className="w-auto min-h-40px h-40px align-center valign-center position-relative" />
							</Group>
						</Group>
						<Text
							id="homeDownloadLogoDisclaimer1668016618728"
							className="w-auto h-auto align-center position-relative m-24px text-text-on-primary f-10px ff-home text-center" >
							{t('home.homeDownloadLogoDisclaimer1668016618728')}
						</Text>
					</Group>
				</Group>
				<Group
					id="homeFooter1668016618728"
					direction="vertical"
					className="w-100 h-auto position-relative bg-primary-light p-16px" >
					<Text
						id="homeFooterText1668016618728"
						className="w-100 h-auto position-relative text-center" >
						{t('home.homeFooterText1668016618728')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
