import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, Button, Image, Form } from '../components';
import { useEffect } from 'react';
import transp from '../assets/transp.png';
import { useTranslation } from 'react-i18next';

export const EventoView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const eventoParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( eventoParams, [])
	const eventoOnLoaded = (options) => {
		run([{"id":"set1668801229697","function":"set","what":"@property.cambioevento","value":"@cookie.evento"}], options)
	}
	useEffect(eventoOnLoaded, [])
	const selectcadaunidadOnChanged = (options) => {
		run([{"function":"set","what":"@element.selectcadaunidad.visible","value":false}], options)
	}
	const inputcadaunidad1669323739951OnClick = (options) => {
		run([{"function":"set","what":"@element.selectcadaunidad.visible","value":true}], options)
	}
	const selectcadanumeroOnChanged = (options) => {
		run([{"function":"set","what":"@element.selectcadanumero.visible","value":false}], options)
	}
	const inputcada1669323739951OnClick = (options) => {
		run([{"function":"set","what":"@element.selectcadanumero.visible","value":true}], options)
	}
	const selecthastaOnChanged = (options) => {
		run([{"function":"set","what":"@element.selecthasta.visible","value":false}], options)
	}
	const inputhasta1669323739951OnClick = (options) => {
		run([{"function":"set","what":"@element.selecthasta.visible","value":true}], options)
	}
	const selectdesdeOnChanged = (options) => {
		run([{"function":"set","what":"@element.selectdesde.visible","value":false}], options)
	}
	const inputdesde1669323739951OnClick = (options) => {
		run([{"function":"set","what":"@element.selectdesde.visible","value":true}], options)
	}
	const button31669OnClick = (options) => {
		run([{"function":"set","what":"@database.eventos.(@cookie.evento).horaparada3","value":"(@property.hparada3):(@property.mparada3)","if":{"what":"@property.hparada3","is_not":null,"and":{"what":"@property.mparada3","is_not":null}}},{"function":"set","what":"@element.gruposelectorparada3.visible","value":false}], options)
	}
	const inputhoraparada9951OnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorparada3.visible","value":true}], options)
	}
	const inputparada3OnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const button1669OnClick = (options) => {
		run([{"function":"set","what":"@database.eventos.(@cookie.evento).horaparada2","value":"(@property.hparada2):(@property.mparada2)","if":{"what":"@property.hparada2","is_not":null,"and":{"what":"@property.mparada2","is_not":null}}},{"function":"set","what":"@element.gruposelectorparada2.visible","value":false}], options)
	}
	const inputhoraparada2OnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorparada2.visible","value":true}], options)
	}
	const inputparada2OnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const button99OnClick = (options) => {
		run([{"function":"set","what":"@database.eventos.(@cookie.evento).horaparada1","value":"(@property.hparada1):(@property.mparada1)","if":{"what":"@property.hparada1","is_not":null,"and":{"what":"@property.mparada1","is_not":null}}},{"function":"set","what":"@element.gruposelectorparada1.visible","value":false}], options)
	}
	const inputhoraparada1OnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorparada1.visible","value":true}], options)
	}
	const inputparada1nombreOnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const inputhorafiesta1668778648865OnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorhorafiesta.visible","value":true}], options)
	}
	const horafiesta1668778648865OnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorhoracere.visible","value":true}], options)
	}
	const inputdireccionfiesta1668778648865OnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const inputlugarfiesta1668778648865OnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const switchbus1668778648865OnChanged = (options) => {
		run([{"function":"set","what":"@property.colorboton","value":"guardarrojo.png"},{"function":"set","what":"@element.grupoparadasbus.visible","value":true,"if":{"what":"@property.bus","is":true,"else":[{"function":"set","what":"@element.grupoparadasbus.visible","value":false}]}}], options)
	}
	const button1668778648865OnClick = (options) => {
		run([{"function":"set","what":"@property.horacere","value":"(@property.hcere):(@property.mcere)","if":{"what":"@property.hcere","is_not":null,"and":{"what":"@property.mcere","is_not":null}}},{"function":"set","what":"@element.gruposelectorhoracere.visible","value":false}], options)
	}
	const inputhoracere1668778648865OnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorhoracere.visible","value":true}], options)
	}
	const texthoracereOnClick = (options) => {
		run([{"function":"set","what":"@element.gruposelectorhoracere.visible","value":true}], options)
	}
	const inputdireccioncere1668778648865OnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const inputlugarcereOnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const inputpresentacionOnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const inputnombresOnChanged = (options) => {
		run([{"function":"set","what":"@property.colorbotonevento","value":"guardarrojo.png"}], options)
	}
	const grupocontactosdetOnClick = (options) => {
		run([{"id":"add1669829066559","function":"add","what":{"undefined":""},"to":""}], options)
	}
	const text1669827906715OnClick = (options) => {
		run([{"id":"add1669829958969","function":"add","what":{"undefined":""},"to":""}], options)
	}
	const buttonsalir1669322832307OnClick = (options) => {
		run([{"function":"logout","provider":"firebase"},{"function":"goto","view":"home"}], options)
	}
	const buttonguardarOnClick = (options) => {
		run([{"function":"add","what":{"lugarcere":"@database.eventos.(@cookie.evento).lugarcere","direccioncere":"@database.eventos.(@cookie.evento).direccioncere","direccionfiesta":"@database.eventos.(@cookie.evento).direccionfiesta","fecha":"@database.eventos.(@cookie.evento).fecha","horacere":"@database.eventos.(@cookie.evento).horacere","horafiesta":"@database.eventos.(@cookie.evento).horafiesta","lugarfiesta":"@database.eventos.(@cookie.evento).lugarfiesta","nombres":"@database.eventos.(@cookie.evento).nombres","presentacion":"@database.eventos.(@cookie.evento).presentacion","vueltacadanumero":"@database.eventos.(@cookie.evento).vueltacadanumero","vueltacadaunidad":"@database.eventos.(@cookie.evento).vueltacadaunidad","vueltadesde":"@database.eventos.(@cookie.evento).vueltadesde","vueltahasta":"@database.eventos.(@cookie.evento).vueltahasta","cadanumero":"@database.eventos.(@cookie.evento).cadanumero","cadaunidad":"@database.eventos.(@cookie.evento).cadaunidad","bussi":"@database.eventos.(@cookie.evento).bussi","mismo":"@database.eventos.(@cookie.evento).mismo","parada1":"@database.eventos.(@cookie.evento).parada1","parada2":"@database.eventos.(@cookie.evento).parada2","parada3":"@database.eventos.(@cookie.evento).parada3","horaparada1":"@database.eventos.(@cookie.evento).horaparada1","horaparada2":"@database.eventos.(@cookie.evento).horaparada2","horparada3":"@database.eventos.(@cookie.evento).horaparada3"},"to":"@firebase.firestore.eventos","key":"@cookie.evento"},{"function":"popup","dialog":"guardarevento"}], options)
	}
	const buttonmusica1669322832307OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/us-central1-perfiles-2ede2.cloudfunctions.net\/datamusica?evento=(@cookie.evento)","name":"transporte.xlsx"}], options)
	}
	const buttontrans1669322832307OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/us-central1-perfiles-2ede2.cloudfunctions.net\/databus?evento=(@cookie.evento)","name":"transporte.xlsx"}], options)
	}
	const buttoninv1669322832307OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/us-central1-perfiles-2ede2.cloudfunctions.net\/dataToExcel?evento=(@cookie.evento)","name":"invitados.xlsx"}], options)
	}
	const button1669322832307OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/firebasestorage.googleapis.com\/v0\/b\/perfiles-2ede2.appspot.com\/o\/qrapp%2Fqr-code.png?alt=media&token=2b1cf594-d6e5-45e4-a81b-50404e00525b","name":"qrvenamiboda.png"}], options)
	}
	const button1668616212875OnClick = (options) => {
		run([{"function":"popup","dialog":"crearevento"}], options)
	}
	const selectevento1668616212875OnChanged = (options) => {
		run([{"function":"set","what":"@cookie.evento","value":"@property.cambioevento"},{"function":"set","what":"@firebase.firestore.usuarios.(@cookie.userid).evento","value":"@property.cambioevento"},{"function":"set","what":"@database.usuarios.(@cookie.userid).evento","value":"@property.cambioevento"}], options)
	}
	const groupeventotodoOnClick = (options) => {
		run([{"id":"add1669830246779","function":"add","what":{"nombres":"@database.eventos.(@cookie.evento).nombres","fecha":"@database.eventos.(@cookie.evento).fecha","lugarcere":"@database.eventos.(@cookie.evento).lugarcere"},"to":"@firebase.firestore.eventos.(@cookie.evento)"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('evento.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="groupeventotodo"
				onClick={groupeventotodoOnClick}
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grrupointeraccion"
					direction="horizontal"
					className="w-90 h-auto align-center position-relative" >
					<Group
						id="gruposelectevento1668616212875"
						direction="vertical"
						className="w-16 min-h-350px h-350px border-primary border-top border-bottom border-start border-end border-radius-25px align-center position-relative bg-traslucido p-50px mt-20px" >
						<Text
							id="text1668616212875"
							className="w-100 h-auto align-center position-relative f-22px ff-renner" >
							{t('evento.text1668616212875')}
						</Text>
						<Input.Select
							id="selectevento1668616212875"
							name="select"
							src="@property.cambioevento"
							dataSrc="@firebase.firestore.wp"
							mapSrc={{"label":"nombreevento","value":"nombreevento"}}
							filter={{"what":"wp","is":"@cookie.codigowp"}}
							onChange={selectevento1668616212875OnChanged}
							className="w-auto h-auto position-relative" >
							<option value={"."}>{t('evento.selectevento1668616212875.opt0')}</option>
						</Input.Select>
						<Button
							id="button1668616212875"
							onClick={button1668616212875OnClick}
							variant="primary"
							className="w-auto h-auto border-radius-50px position-relative mt-24px" >
							{t('evento.button1668616212875')}
						</Button>
					</Group>
					<Group
						id="grupoacciones"
						direction="horizontal"
						className="w-fill min-h-350px h-350px border-primary border-top border-bottom border-start border-end border-radius-25px align-start position-relative p-20px ms-20px mt-30px" >
						<Image
							id="imgevento1669322832307"
							fallback={transp}
							src="@database.eventos.(@cookie.evento).imagen"
							className="w-auto min-h-250px h-250px align-start position-relative m-20px" />
						<Group
							id="grrupoqr"
							direction="vertical"
							className="w-auto h-fill border-primary border-top border-bottom border-start border-end border-radius-50px align-center valign-center position-relative p-30px ms-50px" >
							<Button
								id="button1669322832307"
								onClick={button1669322832307OnClick}
								variant="primary"
								className="w-150px h-auto border-radius-50px align-center position-relative" >
								{t('evento.button1669322832307')}
							</Button>
							<Button
								id="button1669322832307"
								onClick={button1669322832307OnClick}
								variant="primary"
								className="w-150px h-auto border-radius-50px align-center position-relative mt-30px" >
								{t('evento.button1669322832307')}
							</Button>
						</Group>
						<Group
							id="grrupolistados"
							direction="vertical"
							className="w-auto h-fill border-primary border-top border-bottom border-start border-end border-radius-50px align-end valign-center position-relative p-30px ms-50px" >
							<Text
								id="text1669322832307"
								className="w-auto h-auto align-center position-relative f-20px ff-renner" >
								{t('evento.text1669322832307')}
							</Text>
							<Group
								id="grrupoqr1669322832307"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-30px" >
								<Button
									id="buttoninv1669322832307"
									onClick={buttoninv1669322832307OnClick}
									variant="primary"
									className="w-100px h-auto border-radius-50px position-relative" >
									{t('evento.buttoninv1669322832307')}
								</Button>
								<Button
									id="buttontrans1669322832307"
									onClick={buttontrans1669322832307OnClick}
									variant="primary"
									className="w-100px h-auto border-radius-50px position-relative mt-20px" >
									{t('evento.buttontrans1669322832307')}
								</Button>
								<Button
									id="buttonmusica1669322832307"
									onClick={buttonmusica1669322832307OnClick}
									variant="primary"
									className="w-100px h-auto border-radius-50px position-relative mt-20px" >
									{t('evento.buttonmusica1669322832307')}
								</Button>
							</Group>
						</Group>
						<Group
							id="groupguardar1669322832307"
							direction="vertical"
							className="w-auto h-fill border-primary border-top border-bottom border-start border-end border-radius-50px align-end valign-center position-relative p-30px ms-50px" >
							<Button
								id="buttonguardar"
								onClick={buttonguardarOnClick}
								variant="primary"
								className="w-100px h-auto border-radius-50px position-relative" >
								{t('evento.buttonguardar')}
							</Button>
							<Button
								id="buttonsalir1669322832307"
								onClick={buttonsalir1669322832307OnClick}
								variant="primary"
								className="w-100px h-auto border-radius-50px position-relative mt-30px" >
								{t('evento.buttonsalir1669322832307')}
							</Button>
						</Group>
					</Group>
				</Group>
				<Group
					id="tituloeventoactual1670099281919"
					direction="horizontal"
					className="w-auto h-auto align-center position-relative" >
					<Text
						id="textoeventoact1670099281919"
						className="w-auto h-auto valign-center position-relative ff-renner" >
						{t('evento.textoeventoact1670099281919')}
					</Text>
					<Text
						id="texteventocargado1670099281919"
						src="@cookie.evento"
						className="w-auto h-auto position-relative ms-20px f-25px ff-renner" >
						{t('evento.texteventocargado1670099281919')}
					</Text>
				</Group>
				<Group
					id="grupoinfocontactos1669827906715"
					direction="vertical"
					className="w-90 h-auto border-primary border-top border-bottom border-start border-end border-radius-50px align-center position-relative p-30px mt-30px" >
					<Text
						id="text1669827906715"
						onClick={text1669827906715OnClick}
						className="w-auto h-auto align-center position-relative mt-20px f-20px ff-renner" >
						{t('evento.text1669827906715')}
					</Text>
					<Group
						id="grupocontactosdet"
						onClick={grupocontactosdetOnClick}
						direction="horizontal"
						className="w-90 h-auto align-center position-relative p-50px" >
						<Group
							id="grupocontacto1n"
							direction="vertical"
							className="w-33 h-auto position-relative" >
							<Text
								id="titulo1n"
								className="w-100 h-auto align-center position-relative mt-20px f-20px ff-renner" >
								{t('evento.titulo1n')}
							</Text>
							<Form
								id="form1n"
								action="https://target-url"
								className="w-auto h-auto position-relative" >
								<Input.Switch
									id="visible1n"
									label={t('evento.visible1n.label')}
									name="checkbox"
									src="@database.eventos.(@cookie.evento).contacto1visible"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="nombre1n"
									label={t('evento.nombre1n.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contacto1nom"
								placeholder={t('evento.nombre1n.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="telefono1n"
									label={t('evento.telefono1n.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contacto1tel"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Email
									id="mail1n"
									label={t('evento.mail1n.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contacto1mail"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
							</Form>
						</Group>
						<Group
							id="grupocontacto2n"
							direction="vertical"
							className="w-33 h-auto position-relative mx-30px" >
							<Text
								id="titulo2n"
								className="w-100 h-auto align-center position-relative mt-20px f-20px ff-renner" >
								{t('evento.titulo2n')}
							</Text>
							<Form
								id="form2n"
								action="https://target-url"
								className="w-auto h-auto position-relative" >
								<Input.Switch
									id="visible2n"
									label={t('evento.visible2n.label')}
									name="checkbox"
									src="@database.eventos.(@cookie.evento).contacto2visible"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="nombre2n"
									label={t('evento.nombre2n.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contacto2nom"
								placeholder={t('evento.nombre2n.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="telefono2n"
									label={t('evento.telefono2n.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contacto2tel"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Email
									id="mail2n"
									label={t('evento.mail2n.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contacto2mail"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
							</Form>
						</Group>
						<Group
							id="grupocontactowp"
							direction="vertical"
							className="w-33 h-auto position-relative" >
							<Text
								id="titulowp"
								className="w-100 h-auto align-center position-relative mt-20px f-20px ff-renner" >
								{t('evento.titulowp')}
							</Text>
							<Form
								id="formwp"
								action="https://target-url"
								className="w-auto h-auto position-relative" >
								<Input.Switch
									id="visiblewp"
									label={t('evento.visiblewp.label')}
									name="checkbox"
									src="@database.eventos.(@cookie.evento).contactowpvisible"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="nombrewp"
									label={t('evento.nombrewp.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contactowpnom"
								placeholder={t('evento.nombrewp.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="telefonowp"
									label={t('evento.telefonowp.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contactowptel"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Email
									id="mailwp"
									label={t('evento.mailwp.label')}
									name="text"
									src="@database.eventos.(@cookie.evento).contactowpmail"
								autoComplete="off"
									className="w-auto h-auto position-relative" />
							</Form>
						</Group>
					</Group>
				</Group>
				<Group
					id="groupevento1668778648865"
					direction="horizontal"
					className="w-90 h-auto border-primary border-top border-bottom border-start border-end border-radius-50px align-center position-relative p-30px mt-25px" >
					<Group
						id="grupoediteventoinfo1668778648865"
						direction="vertical"
						className="w-33 h-auto border-radius-25px align-center position-relative bg-traslucido p-10px mt-12px" >
						<Text
							id="text1668778648865"
							className="w-100 h-auto position-relative f-20px ff-renner" >
							{t('evento.text1668778648865')}
						</Text>
						<Input.Text
							id="inputnombres"
							label={t('evento.inputnombres.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).nombres"
						placeholder={t('evento.inputnombres.hint')}
						autoComplete="off"
						onChange={inputnombresOnChanged}
							className="w-100 h-auto valign-start position-relative mt-24px" />
						<Input.Text
							id="inputfecha"
							label={t('evento.inputfecha.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).fecha"
						autoComplete="off"
							className="w-auto h-auto position-relative" />
						<Input.Text
							id="inputpresentacion"
							label={t('evento.inputpresentacion.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).presentacion"
						autoComplete="off"
						onChange={inputpresentacionOnChanged}
							className="w-100 h-auto position-relative mt-12px" />
					</Group>
					<Group
						id="grupoeditceremonia"
						direction="vertical"
						className="w-33 h-auto align-center valign-start position-relative pt-12px ms-20px scrollable" >
						<Text
							id="textcere"
							className="w-100 h-auto align-center position-relative f-22px ff-renner text-center" >
							{t('evento.textcere')}
						</Text>
						<Input.Text
							id="inputlugarcere"
							label={t('evento.inputlugarcere.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).lugarcere"
						placeholder={t('evento.inputlugarcere.hint')}
						autoComplete="off"
						onChange={inputlugarcereOnChanged}
							className="w-100 h-auto position-relative mt-12px" />
						<Input.Text
							id="inputdireccioncere1668778648865"
							label={t('evento.inputdireccioncere1668778648865.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).direccioncere"
						placeholder={t('evento.inputdireccioncere1668778648865.hint')}
						autoComplete="off"
						onChange={inputdireccioncere1668778648865OnChanged}
							className="w-100 h-auto valign-start position-relative mt-24px" />
						<Group
							id="grupohoracere1668778648865"
							direction="horizontal"
							className="w-auto h-auto align-center position-relative mt-24px" >
							<Text
								id="texthoracere"
								onClick={texthoracereOnClick}
								className="w-auto h-auto align-center valign-center position-relative me-10px f-20px ff-renner text-center" >
								{t('evento.texthoracere')}
							</Text>
							<Text
								id="inputhoracere1668778648865"
								onClick={inputhoracere1668778648865OnClick}
								src="@database.eventos.(@cookie.evento).horacere"
								className="w-auto h-auto align-center valign-center position-relative f-20px ff-renner text-center" >
								{t('evento.inputhoracere1668778648865')}
							</Text>
							<Group
								id="gruposelectorhoracere1668778648865"
								visible={false}
								direction="horizontal"
								className="w-auto h-auto align-end valign-center position-absolute bg-text-on-accent" >
								<Input.Select
									id="selecthora1668778648865"
									name="select"
									src="@property.hcere"
									className="w-auto h-auto position-relative" >
									<option value={"."}>{t('evento.selecthora1668778648865.opt0')}</option>
									<option value={10}>{t('evento.selecthora1668778648865.opt1')}</option>
									<option value={11}>{t('evento.selecthora1668778648865.opt2')}</option>
									<option value={12}>{t('evento.selecthora1668778648865.opt3')}</option>
									<option value={13}>{t('evento.selecthora1668778648865.opt4')}</option>
									<option value={14}>{t('evento.selecthora1668778648865.opt5')}</option>
									<option value={15}>{t('evento.selecthora1668778648865.opt6')}</option>
									<option value={16}>{t('evento.selecthora1668778648865.opt7')}</option>
									<option value={17}>{t('evento.selecthora1668778648865.opt8')}</option>
									<option value={18}>{t('evento.selecthora1668778648865.opt9')}</option>
									<option value={19}>{t('evento.selecthora1668778648865.opt10')}</option>
									<option value={20}>{t('evento.selecthora1668778648865.opt11')}</option>
									<option value={21}>{t('evento.selecthora1668778648865.opt12')}</option>
								</Input.Select>
								<Text
									id="text1668778648865"
									className="w-auto h-auto valign-center position-relative mx-5px" >
									{t('evento.text1668778648865')}
								</Text>
								<Input.Select
									id="selectminutos1668778648865"
									name="select"
									src="@property.mcere"
									className="w-auto h-auto align-start position-relative" >
									<option value={"."}>{t('evento.selectminutos1668778648865.opt0')}</option>
									<option value={15}>{t('evento.selectminutos1668778648865.opt1')}</option>
									<option value={"00"}>{t('evento.selectminutos1668778648865.opt2')}</option>
									<option value={30}>{t('evento.selectminutos1668778648865.opt3')}</option>
									<option value={45}>{t('evento.selectminutos1668778648865.opt4')}</option>
								</Input.Select>
								<Button
									id="button1668778648865"
									onClick={button1668778648865OnClick}
									variant="primary"
									className="w-auto h-auto position-relative" >
									{t('evento.button1668778648865')}
								</Button>
							</Group>
						</Group>
						<Input.Switch
							id="switchbus1668778648865"
							label={t('evento.switchbus1668778648865.label')}
							name="switch"
							src="@database.eventos.(@cookie.evento).bussi"
						onChange={switchbus1668778648865OnChanged}
							className="w-100 h-auto position-relative m-24px" />
					</Group>
					<Group
						id="grupoeditfiesta1668778648865"
						direction="vertical"
						className="w-33 h-auto align-center valign-start position-relative pt-12px ms-20px scrollable" >
						<Input.Switch
							id="component1668778648865"
							label={t('evento.component1668778648865.label')}
							name="switch"
							src="@database.eventos.(@cookie.evento).mismo"
							className="w-100 h-auto position-relative" />
						<Input.Text
							id="inputlugarfiesta1668778648865"
							label={t('evento.inputlugarfiesta1668778648865.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).lugarfiesta"
						placeholder={t('evento.inputlugarfiesta1668778648865.hint')}
						autoComplete="off"
						onChange={inputlugarfiesta1668778648865OnChanged}
							className="w-100 h-auto position-relative mt-12px" />
						<Input.Text
							id="inputdireccionfiesta1668778648865"
							label={t('evento.inputdireccionfiesta1668778648865.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).direccionfiesta"
						placeholder={t('evento.inputdireccionfiesta1668778648865.hint')}
						autoComplete="off"
						onChange={inputdireccionfiesta1668778648865OnChanged}
							className="w-100 h-auto valign-start position-relative mt-24px" />
						<Group
							id="grupohorafiesta1668778648865"
							direction="horizontal"
							className="w-auto h-auto align-center position-relative mt-24px" >
							<Text
								id="horafiesta1668778648865"
								onClick={horafiesta1668778648865OnClick}
								className="w-auto h-auto align-center valign-center position-relative me-10px f-20px ff-renner text-center" >
								{t('evento.horafiesta1668778648865')}
							</Text>
							<Text
								id="inputhorafiesta1668778648865"
								onClick={inputhorafiesta1668778648865OnClick}
								src="@database.eventos.(@cookie.evento).horafiesta"
								className="w-auto h-auto align-center valign-center position-relative f-20px ff-renner text-center" >
								{t('evento.inputhorafiesta1668778648865')}
							</Text>
							<Group
								id="gruposelectorhorafiesta1668778648865"
								visible={false}
								direction="horizontal"
								className="w-auto h-auto align-center valign-center position-absolute bg-text-on-accent" >
								<Input.Select
									id="selecthora1668778648865"
									name="select"
									src="@property.hfiesta"
									className="w-auto h-auto position-relative" >
									<option value={"."}>{t('evento.selecthora1668778648865.opt0')}</option>
									<option value={10}>{t('evento.selecthora1668778648865.opt1')}</option>
									<option value={11}>{t('evento.selecthora1668778648865.opt2')}</option>
									<option value={12}>{t('evento.selecthora1668778648865.opt3')}</option>
									<option value={13}>{t('evento.selecthora1668778648865.opt4')}</option>
									<option value={14}>{t('evento.selecthora1668778648865.opt5')}</option>
									<option value={15}>{t('evento.selecthora1668778648865.opt6')}</option>
									<option value={16}>{t('evento.selecthora1668778648865.opt7')}</option>
									<option value={17}>{t('evento.selecthora1668778648865.opt8')}</option>
									<option value={18}>{t('evento.selecthora1668778648865.opt9')}</option>
									<option value={19}>{t('evento.selecthora1668778648865.opt10')}</option>
									<option value={20}>{t('evento.selecthora1668778648865.opt11')}</option>
									<option value={21}>{t('evento.selecthora1668778648865.opt12')}</option>
								</Input.Select>
								<Text
									id="text1668778648865"
									className="w-auto h-auto valign-center position-relative mx-5px" >
									{t('evento.text1668778648865')}
								</Text>
								<Input.Select
									id="selectminutos1668778648865"
									name="select"
									src="@property.mfiesta"
									className="w-auto h-auto align-start position-relative" >
									<option value={"."}>{t('evento.selectminutos1668778648865.opt0')}</option>
									<option value={15}>{t('evento.selectminutos1668778648865.opt1')}</option>
									<option value={"00"}>{t('evento.selectminutos1668778648865.opt2')}</option>
									<option value={30}>{t('evento.selectminutos1668778648865.opt3')}</option>
									<option value={45}>{t('evento.selectminutos1668778648865.opt4')}</option>
								</Input.Select>
								<Button
									id="button1668778648865"
									onClick={button1668778648865OnClick}
									variant="primary"
									className="w-auto h-auto position-relative" >
									{t('evento.button1668778648865')}
								</Button>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="grupoparadasbus"
					direction="vertical"
					className="w-34 h-auto border-primary border-top border-bottom border-start border-end border-radius-50px align-center position-relative p-20px mt-30px" >
					<Text
						id="ida1669323739951"
						className="w-100 h-auto align-center position-relative mt-12px f-22px ff-renner text-center" >
						{t('evento.ida1669323739951')}
					</Text>
					<Group
						id="grupoparada1"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Input.Text
							id="inputparada1nombre"
							label={t('evento.inputparada1nombre.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).parada1"
						autoComplete="off"
						onChange={inputparada1nombreOnChanged}
							className="w-fill h-auto position-relative mt-12px" />
						<Group
							id="grupohoraparada1"
							direction="horizontal"
							className="w-auto h-auto align-center position-relative" >
							<Text
								id="texthoraparada1"
								className="w-auto h-auto align-center valign-center position-relative me-10px f-20px ff-renner text-center" >
								{t('evento.texthoraparada1')}
							</Text>
							<Text
								id="inputhoraparada1"
								onClick={inputhoraparada1OnClick}
								src="@database.eventos.(@cookie.evento).horaparada1"
								className="w-auto h-auto align-center valign-end position-relative f-20px ff-renner text-center" >
								{t('evento.inputhoraparada1')}
							</Text>
							<Group
								id="gruposelectorparada1"
								visible={false}
								direction="horizontal"
								className="w-auto h-auto align-end valign-center position-absolute bg-text-on-accent" >
								<Input.Select
									id="selecthora1"
									name="select"
									src="@property.hparada1"
									className="w-auto h-auto valign-end position-relative" >
									<option value={"."}>{t('evento.selecthora1.opt0')}</option>
									<option value={10}>{t('evento.selecthora1.opt1')}</option>
									<option value={11}>{t('evento.selecthora1.opt2')}</option>
									<option value={12}>{t('evento.selecthora1.opt3')}</option>
									<option value={13}>{t('evento.selecthora1.opt4')}</option>
									<option value={14}>{t('evento.selecthora1.opt5')}</option>
									<option value={15}>{t('evento.selecthora1.opt6')}</option>
									<option value={16}>{t('evento.selecthora1.opt7')}</option>
									<option value={17}>{t('evento.selecthora1.opt8')}</option>
									<option value={18}>{t('evento.selecthora1.opt9')}</option>
									<option value={19}>{t('evento.selecthora1.opt10')}</option>
									<option value={20}>{t('evento.selecthora1.opt11')}</option>
									<option value={21}>{t('evento.selecthora1.opt12')}</option>
								</Input.Select>
								<Text
									id="text1selecthoraparada1"
									className="w-auto h-auto valign-center position-relative mx-5px" >
									{t('evento.text1selecthoraparada1')}
								</Text>
								<Input.Select
									id="selectminutos1669323739951"
									name="select"
									src="@property.mparada1"
									className="w-auto h-auto align-start position-relative" >
									<option value={"."}>{t('evento.selectminutos1669323739951.opt0')}</option>
									<option value={"00"}>{t('evento.selectminutos1669323739951.opt1')}</option>
									<option value={15}>{t('evento.selectminutos1669323739951.opt2')}</option>
									<option value={30}>{t('evento.selectminutos1669323739951.opt3')}</option>
									<option value={45}>{t('evento.selectminutos1669323739951.opt4')}</option>
								</Input.Select>
								<Button
									id="button99"
									onClick={button99OnClick}
									variant="primary"
									className="w-auto h-auto position-relative" >
									{t('evento.button99')}
								</Button>
							</Group>
						</Group>
					</Group>
					<Group
						id="grupoparada2"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Input.Text
							id="inputparada2"
							label={t('evento.inputparada2.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).parada2"
						autoComplete="off"
						onChange={inputparada2OnChanged}
							className="w-fill h-auto position-relative mt-12px" />
						<Group
							id="grupohoraparada2"
							direction="horizontal"
							className="w-auto h-auto align-center position-relative" >
							<Text
								id="texthoraparada2"
								className="w-auto h-auto align-center valign-center position-relative me-10px f-20px ff-renner text-center" >
								{t('evento.texthoraparada2')}
							</Text>
							<Text
								id="inputhoraparada2"
								onClick={inputhoraparada2OnClick}
								src="@database.eventos.(@cookie.evento).horaparada2"
								className="w-auto h-auto align-center valign-end position-relative f-20px ff-renner text-center" >
								{t('evento.inputhoraparada2')}
							</Text>
							<Group
								id="gruposelectorparada2"
								visible={false}
								direction="horizontal"
								className="w-auto h-auto align-end valign-center position-absolute bg-text-on-accent" >
								<Input.Select
									id="selecthora1669323739951"
									name="select"
									src="@property.hparada2"
									className="w-auto h-auto valign-end position-relative" >
									<option value={"."}>{t('evento.selecthora1669323739951.opt0')}</option>
									<option value={10}>{t('evento.selecthora1669323739951.opt1')}</option>
									<option value={11}>{t('evento.selecthora1669323739951.opt2')}</option>
									<option value={12}>{t('evento.selecthora1669323739951.opt3')}</option>
									<option value={13}>{t('evento.selecthora1669323739951.opt4')}</option>
									<option value={14}>{t('evento.selecthora1669323739951.opt5')}</option>
									<option value={15}>{t('evento.selecthora1669323739951.opt6')}</option>
									<option value={16}>{t('evento.selecthora1669323739951.opt7')}</option>
									<option value={17}>{t('evento.selecthora1669323739951.opt8')}</option>
									<option value={18}>{t('evento.selecthora1669323739951.opt9')}</option>
									<option value={19}>{t('evento.selecthora1669323739951.opt10')}</option>
									<option value={20}>{t('evento.selecthora1669323739951.opt11')}</option>
									<option value={21}>{t('evento.selecthora1669323739951.opt12')}</option>
								</Input.Select>
								<Text
									id="text1669"
									className="w-auto h-auto valign-center position-relative mx-5px" >
									{t('evento.text1669')}
								</Text>
								<Input.Select
									id="selectminutos1669"
									name="select"
									src="@property.mparada2"
									className="w-auto h-auto align-start position-relative" >
									<option value={"."}>{t('evento.selectminutos1669.opt0')}</option>
									<option value={15}>{t('evento.selectminutos1669.opt1')}</option>
									<option value={"00"}>{t('evento.selectminutos1669.opt2')}</option>
									<option value={30}>{t('evento.selectminutos1669.opt3')}</option>
									<option value={45}>{t('evento.selectminutos1669.opt4')}</option>
								</Input.Select>
								<Button
									id="button1669"
									onClick={button1669OnClick}
									variant="primary"
									className="w-auto h-auto position-relative" >
									{t('evento.button1669')}
								</Button>
							</Group>
						</Group>
					</Group>
					<Group
						id="grupoparada3"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Input.Text
							id="inputparada3"
							label={t('evento.inputparada3.label')}
							name="text"
							src="@database.eventos.(@cookie.evento).parada3"
						autoComplete="off"
						onChange={inputparada3OnChanged}
							className="w-fill h-auto position-relative mt-12px" />
						<Group
							id="grupohoraparada3"
							direction="horizontal"
							className="w-auto h-auto align-center position-relative" >
							<Text
								id="texthoraparada9951"
								className="w-auto h-auto align-center valign-center position-relative me-10px f-20px ff-renner text-center" >
								{t('evento.texthoraparada9951')}
							</Text>
							<Text
								id="inputhoraparada9951"
								onClick={inputhoraparada9951OnClick}
								src="@database.eventos.(@cookie.evento).horaparada3"
								className="w-auto h-auto align-center valign-end position-relative f-20px ff-renner text-center" >
								{t('evento.inputhoraparada9951')}
							</Text>
							<Group
								id="gruposelectorparada3"
								visible={false}
								direction="horizontal"
								className="w-auto h-auto align-end valign-center position-absolute bg-text-on-accent" >
								<Input.Select
									id="selecthora1669323739951"
									name="select"
									src="@property.hparada3"
									className="w-auto h-auto valign-end position-relative" >
									<option value={"."}>{t('evento.selecthora1669323739951.opt0')}</option>
									<option value={10}>{t('evento.selecthora1669323739951.opt1')}</option>
									<option value={11}>{t('evento.selecthora1669323739951.opt2')}</option>
									<option value={12}>{t('evento.selecthora1669323739951.opt3')}</option>
									<option value={13}>{t('evento.selecthora1669323739951.opt4')}</option>
									<option value={14}>{t('evento.selecthora1669323739951.opt5')}</option>
									<option value={15}>{t('evento.selecthora1669323739951.opt6')}</option>
									<option value={16}>{t('evento.selecthora1669323739951.opt7')}</option>
									<option value={17}>{t('evento.selecthora1669323739951.opt8')}</option>
									<option value={18}>{t('evento.selecthora1669323739951.opt9')}</option>
									<option value={19}>{t('evento.selecthora1669323739951.opt10')}</option>
									<option value={20}>{t('evento.selecthora1669323739951.opt11')}</option>
									<option value={21}>{t('evento.selecthora1669323739951.opt12')}</option>
								</Input.Select>
								<Text
									id="text19951"
									className="w-auto h-auto valign-center position-relative mx-5px" >
									{t('evento.text19951')}
								</Text>
								<Input.Select
									id="selectminutos39951"
									name="select"
									src="@property.mparada3"
									className="w-auto h-auto align-start position-relative" >
									<option value={"."}>{t('evento.selectminutos39951.opt0')}</option>
									<option value={15}>{t('evento.selectminutos39951.opt1')}</option>
									<option value={"00"}>{t('evento.selectminutos39951.opt2')}</option>
									<option value={30}>{t('evento.selectminutos39951.opt3')}</option>
									<option value={45}>{t('evento.selectminutos39951.opt4')}</option>
								</Input.Select>
								<Button
									id="button31669"
									onClick={button31669OnClick}
									variant="primary"
									className="w-auto h-auto position-relative" >
									{t('evento.button31669')}
								</Button>
							</Group>
						</Group>
					</Group>
					<Text
						id="vuelta1669323739951"
						className="w-100 h-auto align-center position-relative mt-12px f-22px ff-renner text-center" >
						{t('evento.vuelta1669323739951')}
					</Text>
					<Group
						id="horariovuelta1669323739951"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Group
							id="grupodesde1669323739951"
							direction="vertical"
							className="w-auto h-auto position-relative me-20px" >
							<Text
								id="textodesde1669323739951"
								className="w-auto h-auto position-relative" >
								{t('evento.textodesde1669323739951')}
							</Text>
							<Text
								id="inputdesde1669323739951"
								onClick={inputdesde1669323739951OnClick}
								src="@database.eventos.(@cookie.evento).vueltadesde"
								className="w-auto h-auto position-relative ms-5px mt-10px" >
								{t('evento.inputdesde1669323739951')}
							</Text>
							<Input.Select
								id="selectdesde"
								visible={false}
								name="select"
								src="@database.eventos.(@cookie.evento).vueltadesde"
								onChange={selectdesdeOnChanged}
								className="w-auto h-auto align-end position-absolute bg-text-on-accent" >
								<option value={"."}>{t('evento.selectdesde.opt0')}</option>
								<option value={"19:00"}>{t('evento.selectdesde.opt1')}</option>
								<option value={"20:00"}>{t('evento.selectdesde.opt2')}</option>
								<option value={"21:00"}>{t('evento.selectdesde.opt3')}</option>
								<option value={"22:00"}>{t('evento.selectdesde.opt4')}</option>
								<option value={"23:00"}>{t('evento.selectdesde.opt5')}</option>
								<option value={"00:00"}>{t('evento.selectdesde.opt6')}</option>
								<option value={"01:00"}>{t('evento.selectdesde.opt7')}</option>
								<option value={"02:00"}>{t('evento.selectdesde.opt8')}</option>
								<option value={"03:00"}>{t('evento.selectdesde.opt9')}</option>
							</Input.Select>
						</Group>
						<Group
							id="grupohasta1669323739951"
							direction="vertical"
							className="w-auto h-auto position-relative" >
							<Text
								id="textohasta1669323739951"
								className="w-auto h-auto position-relative" >
								{t('evento.textohasta1669323739951')}
							</Text>
							<Text
								id="inputhasta1669323739951"
								onClick={inputhasta1669323739951OnClick}
								src="@database.eventos.(@cookie.evento).vueltahasta"
								className="w-auto h-auto position-relative ms-5px mt-10px" >
								{t('evento.inputhasta1669323739951')}
							</Text>
							<Input.Select
								id="selecthasta"
								visible={false}
								name="select"
								src="@database.eventos.(@cookie.evento).vueltahasta"
								onChange={selecthastaOnChanged}
								className="w-auto h-auto align-end position-absolute ms-20px" >
								<option value={"."}>{t('evento.selecthasta.opt0')}</option>
								<option value={"19:00"}>{t('evento.selecthasta.opt1')}</option>
								<option value={"20:00"}>{t('evento.selecthasta.opt2')}</option>
								<option value={"21:00"}>{t('evento.selecthasta.opt3')}</option>
								<option value={"22:00"}>{t('evento.selecthasta.opt4')}</option>
								<option value={"23:00"}>{t('evento.selecthasta.opt5')}</option>
								<option value={"00:00"}>{t('evento.selecthasta.opt6')}</option>
								<option value={"01:00"}>{t('evento.selecthasta.opt7')}</option>
								<option value={"02:00"}>{t('evento.selecthasta.opt8')}</option>
								<option value={"03:00"}>{t('evento.selecthasta.opt9')}</option>
							</Input.Select>
						</Group>
						<Group
							id="cadenciavuelta1669323739951"
							direction="horizontal"
							className="w-100 h-auto position-relative ms-30px" >
							<Group
								id="grupocadanumero1669323739951"
								direction="vertical"
								className="w-auto h-auto position-relative" >
								<Text
									id="textocada1669323739951"
									className="w-auto h-auto position-relative" >
									{t('evento.textocada1669323739951')}
								</Text>
								<Text
									id="inputcada1669323739951"
									onClick={inputcada1669323739951OnClick}
									src="@database.eventos.(@cookie.evento).cadanumero"
									className="w-auto h-auto position-relative mt-10px" >
									{t('evento.inputcada1669323739951')}
								</Text>
								<Input.Select
									id="selectcadanumero"
									visible={false}
									name="select"
									src="@database.eventos.(@cookie.evento).cadanumero"
									onChange={selectcadanumeroOnChanged}
									className="w-auto h-auto align-end position-absolute" >
									<option value={"."}>{t('evento.selectcadanumero.opt0')}</option>
									<option value={1}>{t('evento.selectcadanumero.opt1')}</option>
									<option value={2}>{t('evento.selectcadanumero.opt2')}</option>
									<option value={15}>{t('evento.selectcadanumero.opt3')}</option>
									<option value={30}>{t('evento.selectcadanumero.opt4')}</option>
									<option value={45}>{t('evento.selectcadanumero.opt5')}</option>
								</Input.Select>
							</Group>
							<Group
								id="grupocadaunidad1669323739951"
								direction="vertical"
								className="w-auto h-auto position-relative ms-30px" >
								<Text
									id="inputcadaunidad1669323739951"
									onClick={inputcadaunidad1669323739951OnClick}
									src="@database.eventos.(@cookie.evento).cadaunidad"
									className="w-auto h-auto valign-end position-relative ms-5px mt-10px" >
									{t('evento.inputcadaunidad1669323739951')}
								</Text>
								<Input.Select
									id="selectcadaunidad"
									visible={false}
									name="select"
									src="@database.eventos.(@cookie.evento).cadaunidad"
									onChange={selectcadaunidadOnChanged}
									className="w-auto h-auto align-end valign-end position-absolute-bottom ms-20px" >
									<option value={"."}>{t('evento.selectcadaunidad.opt0')}</option>
									<option value={"minutos"}>{t('evento.selectcadaunidad.opt1')}</option>
									<option value={"hora"}>{t('evento.selectcadaunidad.opt2')}</option>
									<option value={"horas"}>{t('evento.selectcadaunidad.opt3')}</option>
								</Input.Select>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
