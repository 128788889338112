import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { AlipdfView, BuspdfView, Carga1View, ConfiguracionView, EventoView, GaleriaView, HomeView, InvitadosView, ListadoaliView, ListadobusView, ListadomusicView, MusicpdfView, PerfilView, PoliticasView, PrivacidadView } from './pages';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={HomeView} />
						<Route exact path="/alipdf" component={AlipdfView} />
<Route exact path="/alipdf/:params(.+)" component={AlipdfView} />
						<Route exact path="/buspdf" component={BuspdfView} />
						<Route exact path="/buspdf/:params(.+)" component={BuspdfView} />
						<Route exact path="/carga1" component={Carga1View} />
						<Route exact path="/carga1/:params(.+)" component={Carga1View} />
						<Route exact path="/configuracion" component={ConfiguracionView} />
						<Route exact path="/configuracion/:params(.+)" component={ConfiguracionView} />
						<Route exact path="/evento" component={EventoView} />
						<Route exact path="/evento/:params(.+)" component={EventoView} />
						<Route exact path="/galeria" component={GaleriaView} />
						<Route exact path="/galeria/:params(.+)" component={GaleriaView} />
						<Route exact path="/home" component={HomeView} />
						<Route exact path="/home/:params(.+)" component={HomeView} />
						<Route exact path="/invitados" component={InvitadosView} />
						<Route exact path="/invitados/:params(.+)" component={InvitadosView} />
						<Route exact path="/listadoali" component={ListadoaliView} />
						<Route exact path="/listadoali/:params(.+)" component={ListadoaliView} />
						<Route exact path="/listadobus" component={ListadobusView} />
						<Route exact path="/listadobus/:params(.+)" component={ListadobusView} />
						<Route exact path="/listadomusic" component={ListadomusicView} />
						<Route exact path="/listadomusic/:params(.+)" component={ListadomusicView} />
						<Route exact path="/musicpdf" component={MusicpdfView} />
						<Route exact path="/musicpdf/:params(.+)" component={MusicpdfView} />
						<Route exact path="/perfil" component={PerfilView} />
						<Route exact path="/perfil/:params(.+)" component={PerfilView} />
						<Route exact path="/politicas" component={PoliticasView} />
						<Route exact path="/politicas/:params(.+)" component={PoliticasView} />
						<Route exact path="/privacidad" component={PrivacidadView} />
						<Route exact path="/privacidad/:params(.+)" component={PrivacidadView} />

          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
