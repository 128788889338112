import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, List, Image } from '../components';
import cruzcerrar from '../assets/cruzcerrar.png';
import download from '../assets/download.png';
import fletxaendavant from '../assets/fletxaendavant.png';
import archivo from '../assets/archivo.png';
import fletxaenrera from '../assets/fletxaenrera.png';
import transp from '../assets/transp.png';
import { useTranslation } from 'react-i18next';

export const GaleriaView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const image1671018537048OnClick = (options) => {
		run([{"id":"set1671021786472","function":"set","what":"@property.mostrarimagen","value":null}], options)
	}
	const decargar1670936467029OnClick = (options) => {
		run([{"id":"download1671017964343","function":"download","url":"@firebase.storage.eventos\/(@cookie.evento)\/galeria\/(@property.galerycontador).jpg#url","name":"imagen1.jpg"}], options)
	}
	const endavant1670936467029OnClick = (options) => {
		run([{"id":"set1670936467029","function":"set","what":"@property.galerycontador","value":"@property.galerycontador.+1","if":{"what":"@property.galerycontador","less_than":"@database.eventos.(@cookie.evento).contador"}},{"id":"set1670936467029","function":"set","what":"@property.mostrarimagen","value":"@firebase.storage.eventos\/(@cookie.evento)\/galeria\/m\/(@property.galerycontador)_800x800.jpg"}], options)
	}
	const enrera1670936467029OnClick = (options) => {
		run([{"id":"set1670936467029","function":"set","what":"@property.galerycontador","value":"@property.galerycontador.-1","if":{"what":"@property.galerycontador","more_than":1}},{"id":"set1670936467029","function":"set","what":"@property.mostrarimagen","value":"@firebase.storage.eventos\/(@cookie.evento)\/galeria\/m\/(@property.galerycontador)_800x800.jpg"}], options)
	}
	const filalista1669463383632OnClick = (options) => {
		run([{"function":"set","what":"@property.galeryselected","value":"@element.listagaleria.value"},{"function":"set","what":"@property.galerycontador","value":"@element.listagaleria.selected"},{"id":"set1670677167368","function":"set","what":"@property.mostrarimagen","value":"@property.galeryselected.imagenm"},{"id":"set1671020777421","function":"set","what":"@property.imagengalerydescargar","value":"@"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('galeria.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="grupogaleria"
				direction="vertical"
				className="w-90 h-auto align-center position-relative mt-20px" >
				<Group
					id="contenidogaleria1669463383632"
					direction="vertical"
					className="w-95 h-fill border-radius-25px align-center position-relative bg-traslucido mt-10px mb-75px" >
					<List
						id="listagaleria"
						src="@firebase.firestore.eventos.(@cookie.evento).galeria"
						columns={5}
						className="w-100 h-auto valign-start position-relative" >
						<Group
							id="filalista1669463383632"
							onClick={filalista1669463383632OnClick}
							direction="vertical"
							className="w-100 h-auto valign-start position-relative" >
							<Image
								id="component1669463383632"
								fallback={transp}
								src="imagens"
								className="w-auto min-h-100 fg-100 align-center position-relative p-5px cover" />
						</Group>
					</List>
				</Group>
			</Group>
			<Group
				id="grupozoomgalery"
				if={{"what":"@property.mostrarimagen","is_not":null}}
				direction="vertical"
				className="w-90 h-auto align-center position-absolute bg-oscurecido mt-50px mb-10px" >
				<Group
					id="grouppasarimagengalery"
					direction="horizontal"
					className="w-auto h-auto align-center position-relative" >
					<Image
						id="enrera1670936467029"
						onClick={enrera1670936467029OnClick}
						src={fletxaenrera}
						className="w-auto min-h-100px h-100px valign-center position-relative" />
					<Image
						id="image1670936467029"
						fallback={archivo}
						src="@property.mostrarimagen"
						className="w-auto min-h-500px h-500px align-center valign-center position-relative m-50px" />
					<Image
						id="endavant1670936467029"
						onClick={endavant1670936467029OnClick}
						src={fletxaendavant}
						className="w-auto min-h-100px h-100px valign-center position-relative" />
				</Group>
				<Image
					id="decargar1670936467029"
					onClick={decargar1670936467029OnClick}
					src={download}
					className="w-auto min-h-100px h-100px align-center valign-center position-relative" />
				<Image
					id="image1671018537048"
					onClick={image1671018537048OnClick}
					src={cruzcerrar}
					className="w-auto min-h-75px h-75px align-end valign-start position-absolute p-10px" />
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
