import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Lottie } from '../components';
import { useEffect } from 'react';
import res_8176lovehearts_8135caa398016dde66105fa89632c14e from '../assets/res_8176lovehearts_8135caa398016dde66105fa89632c14e.json';
import logo from '../assets/logo.png';
import { useTranslation } from 'react-i18next';

export const Carga1View = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const carga1Params = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( carga1Params, [])
	const carga1OnLoaded = (options) => {
		run([{"id":"clone1684319380841","function":"clone","what":"@firebase.firestore.usuarios","into":"@database.usuarios"},{"id":"clone1684319380841","function":"clone","what":"@firebase.firestore.eventos","into":"@database.eventos"},{"id":"wait1684319380841","function":"wait","seconds":4},{"id":"goto1684319380841","function":"goto","view":"perfil"}], options)
	}
	useEffect(carga1OnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('carga1.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="group1670100631669"
				direction="vertical"
				className="w-100 h-auto position-absolute" >
			</Group>
			<Group
				id="logosplash1670100833430"
				direction="vertical"
				className="w-28 h-auto align-center valign-start position-relative mt-48px" >
				<Image
					id="logologin1670100833430"
					src={logo}
					className="w-37 h-auto align-center position-relative" />
				<Text
					id="titulologin1670100833430"
					className="w-100 h-auto position-relative mt-15px f-40px ff-greatvibesregular text-center" >
					{t('carga1.titulologin1670100833430')}
				</Text>
				<Lottie
					id="lottie1670100659491"
					src={res_8176lovehearts_8135caa398016dde66105fa89632c14e}
					className="w-47 h-auto align-center valign-center position-relative" />
			</Group>
      </FullscreenWrapper>
    </>
  );
};
