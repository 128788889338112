import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const BuspdfView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const buspdfParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( buspdfParams, [])
	const buspdfOnLoaded = (options) => {
		run([{"id":"set1686766774144","function":"set","what":"@property.id","value":"@cookie.evento"}], options)
	}
	useEffect(buspdfOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('buspdf.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="grupolistadobus1684319543354"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grrupo1parada1684319543354"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textoparada1"
						src="@firebase.firestore.eventos.(@property.event).parada1"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('buspdf.textoparada1')}
					</Text>
					<Group
						id="grrupop1cabecera1684319543354"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre1684319543354"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabenombre1684319543354')}
						</Text>
						<Text
							id="cabeTelefono1684319543354"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabeTelefono1684319543354')}
						</Text>
						<Text
							id="cabepasajeros1684319543354"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabepasajeros1684319543354')}
						</Text>
					</Group>
					<List
						id="listabus1parada"
						src="@firebase.firestore.usuarios"
						filter={{"what":"parada","is":"@firebase.firestore.eventos.(@property.event).parada1"}}
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
						<Group
							id="grrupop1cabecera1684319543354"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombre1684319543354"
								src="nombre"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineanombre1684319543354')}
							</Text>
							<Text
								id="lineaTelefono1684319543354"
								src="Telefono"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineaTelefono1684319543354')}
							</Text>
							<Text
								id="lineapasajeros1684319543354"
								src="pasajeros"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineapasajeros1684319543354')}
							</Text>
						</Group>
					</List>
				</Group>
				<Group
					id="grrupo2parada1688668547982"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textoparada1688668547982"
						src="@firebase.firestore.eventos.(@property.event).parada2"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('buspdf.textoparada1688668547982')}
					</Text>
					<Group
						id="grrupop2cabecera1688668547982"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre1688668547982"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabenombre1688668547982')}
						</Text>
						<Text
							id="cabeTelefono1688668547982"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabeTelefono1688668547982')}
						</Text>
						<Text
							id="cabepasajeros1688668547982"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabepasajeros1688668547982')}
						</Text>
					</Group>
					<List
						id="listabusp1688668547982"
						src="@firebase.firestore.usuarios"
						filter={{"what":"parada","is":"@firebase.firestore.eventos.(@property.event).parada2"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="grrupop1cabecera1688668547982"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombre1688668547982"
								src="nombre"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineanombre1688668547982')}
							</Text>
							<Text
								id="lineaTelefono1688668547982"
								src="Telefono"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineaTelefono1688668547982')}
							</Text>
							<Text
								id="lineapasajeros1688668547982"
								src="pasajeros"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineapasajeros1688668547982')}
							</Text>
						</Group>
					</List>
				</Group>
				<Group
					id="grrupo3parada1684319543354"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textoparada1684319543354"
						src="@firebase.firestore.eventos.(@property.event).parada3"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('buspdf.textoparada1684319543354')}
					</Text>
					<Group
						id="grrupop3cabecera1684319543354"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre1684319543354"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabenombre1684319543354')}
						</Text>
						<Text
							id="cabeTelefono1684319543354"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabeTelefono1684319543354')}
						</Text>
						<Text
							id="cabepasajeros1684319543354"
							className="w-33 h-auto position-relative" >
							{t('buspdf.cabepasajeros1684319543354')}
						</Text>
					</Group>
					<List
						id="listabusp3"
						src="@firebase.firestore.usuarios"
						filter={{"what":"parada","is":"@firebase.firestore.eventos.(@property.event).parada3"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="grrupop3lista1684319543354"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombrep1684319543354"
								src="nombre"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineanombrep1684319543354')}
							</Text>
							<Text
								id="lineaTelefono1684319543354"
								src="Telefono"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineaTelefono1684319543354')}
							</Text>
							<Text
								id="lineapasajeros1684319543354"
								src="pasajeros"
								className="w-33 h-auto position-relative" >
								{t('buspdf.lineapasajeros1684319543354')}
							</Text>
						</Group>
					</List>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
