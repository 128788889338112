import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const ListadomusicView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const beditdescgaleria1684750299275OnClick = (options) => {
		run([{"function":"download","url":"https:\/\/us-central1-perfiles-2ede2.cloudfunctions.net\/datamusica?evento=(@cookie.evento)","name":"musica.xlsx"}], options)
	}
	const botonpdf1688412127522OnClick = (options) => {
		run([{"id":"api1688412127522","function":"api","api":{"method":"doppio.pdf.sync","url":"https:\/\/venamiboda.net\/musicpdf?event=(@cookie.evento)"},"onsuccess":[{"id":"set1688412127522","function":"set","what":"@cookie.pdf","value":"@cookie.respuesta.documentUrl"},{"id":"download1688412127522","function":"download","url":"@cookie.pdf","name":"listadobus.pdf"}],"into":"@cookie.respuesta"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('listadomusic.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="grrupomusica"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="grrupolistamusic"
					direction="vertical"
					className="w-70 h-auto align-center position-relative mt-20px" >
					<Text
						id="textotitulomusic"
						className="w-auto h-auto align-center position-relative f-25px" >
						{t('listadomusic.textotitulomusic')}
					</Text>
					<Group
						id="grrupocabemusic"
						direction="horizontal"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-accent" >
						<Text
							id="cabenombre"
							className="w-33 h-auto position-relative" >
							{t('listadomusic.cabenombre')}
						</Text>
						<Text
							id="cabecantante"
							className="w-33 h-auto position-relative" >
							{t('listadomusic.cabecantante')}
						</Text>
						<Text
							id="cabecomenmusic"
							className="w-33 h-auto position-relative" >
							{t('listadomusic.cabecomenmusic')}
						</Text>
					</Group>
					<List
						id="listamusic"
						src="@firebase.firestore.eventos.(@cookie.evento).musica"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
						<Group
							id="grrupolineamusic"
							direction="horizontal"
							className="w-100 h-auto border-top border-bottom border-start border-end position-relative" >
							<Text
								id="lineanombre1671364913279"
								src="titulo"
								className="w-33 h-auto position-relative" >
								{t('listadomusic.lineanombre1671364913279')}
							</Text>
							<Text
								id="lineacantante"
								src="cantante"
								className="w-33 h-auto position-relative" >
								{t('listadomusic.lineacantante')}
							</Text>
							<Text
								id="lineacomenmusic"
								src="comentario"
								className="w-33 h-auto position-relative" >
								{t('listadomusic.lineacomenmusic')}
							</Text>
						</Group>
					</List>
				</Group>
				<Group
					id="groupexport1684750299275"
					direction="horizontal"
					className="w-auto h-auto align-end valign-start position-absolute m-30px" >
					<Text
						id="botonpdf1688412127522"
						onClick={botonpdf1688412127522OnClick}
						className="min-w-0px w-auto h-auto border-radius-10px align-end valign-start position-relative bg-primary p-10px me-15px text-white f-20px" >
						{t('listadomusic.botonpdf1688412127522')}
					</Text>
					<Text
						id="beditdescgaleria1684750299275"
						onClick={beditdescgaleria1684750299275OnClick}
						className="w-auto h-auto border-radius-10px align-end valign-start position-relative bg-primary p-10px text-white f-20px" >
						{t('listadomusic.beditdescgaleria1684750299275')}
					</Text>
				</Group>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
