import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { AreaclienteWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Image } from '../components';
import { useEffect } from 'react';
import m5 from '../assets/config/m5.png';
import m4 from '../assets/config/m4.png';
import m3 from '../assets/config/m3.png';
import m2 from '../assets/config/m2.png';
import m1 from '../assets/config/m1.png';
import imgevento from '../assets/imgevento.jpg';
import f6 from '../assets/config/f6.jpg';
import f5 from '../assets/config/f5.jpg';
import f4 from '../assets/config/f4.jpg';
import f3 from '../assets/config/f3.jpg';
import f2 from '../assets/config/f2.jpg';
import f1 from '../assets/config/f1.jpg';
import transp from '../assets/transp.png';
import { useTranslation } from 'react-i18next';

export const ConfiguracionView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const configuracionParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( configuracionParams, [])
	const configuracionOnLoaded = (options) => {
		run([{"id":"set1671397548196","function":"set","what":"@property.fondoactual","value":"@database.eventos.(@cookie.evento).fondoapp"},{"id":"set1671397604485","function":"set","what":"@property.marcoactual","value":"@database.eventos.(@cookie.evento).marco"}], options)
	}
	useEffect(configuracionOnLoaded, [])
	const grrupoalexOnClick = (options) => {
		run([{"id":"set1670679593117","function":"set","what":"@property.font","value":"font.alexbrushregular"},{"id":"set1670679593117","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fuente","value":"@(@property.font)"},{"id":"set1670679593117","function":"set","what":"@database.eventos.(@cookie.evento).fuente","value":"@(@property.font)"},{"id":"do1670679593117","function":"do","what":[{"id":"set1670679593117","function":"set","what":"@element.grupoblack.backgroundColor","value":"#ffffff"},{"id":"set1670679593117","function":"set","what":"@element.grupogreat.backgroundColor","value":"#ffffff"},{"id":"set1670679593117","function":"set","what":"@element.grupohillda.backgroundColor","value":"#ffffff"},{"id":"set1670679593117","function":"set","what":"@element.grupowander.backgroundColor","value":"#ffffff"}]},{"id":"set1670679593117","function":"set","what":"@element.grupoalex.backgroundColor","value":"#80cbc4"}], options)
	}
	const imagemarcoOnClick = (options) => {
		run([{"id":"set1669400221185","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).marco","value":"m4.png"},{"id":"set1669400221185","function":"set","what":"@database.eventos.(@cookie.evento).marco","value":"m5.png"},{"id":"set1671397452909","function":"set","what":"@property.marcoactual","value":"config\/m5.png"}], options)
	}
	const imagenmarco4OnClick = (options) => {
		run([{"id":"set1669398873962","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fondoapp","value":"f4.jpg"},{"id":"set1669398873962","function":"set","what":"@database.eventos.(@cookie.evento).fondoapp","value":"f4.jpg"},{"id":"set1671397440063","function":"set","what":"@property.marcoactual","value":"config\/m4.png"}], options)
	}
	const imagemarco3OnClick = (options) => {
		run([{"id":"set1669400171750","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).marco","value":"m3.png"},{"id":"set1669400171750","function":"set","what":"@database.eventos.(@cookie.evento).marco","value":"m3.png"},{"id":"set1671397425581","function":"set","what":"@property.marcoactual","value":"config\/m3.png"}], options)
	}
	const imagemarco2OnClick = (options) => {
		run([{"id":"set1669398873962","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).marco","value":"m2.png"},{"id":"set1669398873962","function":"set","what":"@database.eventos.(@cookie.evento).marco","value":"m2.png"},{"id":"set1671397408068","function":"set","what":"@property.marcoactual","value":"config\/m2.png"}], options)
	}
	const imagemarco1OnClick = (options) => {
		run([{"id":"set1669398873962","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).marco","value":"m1.png"},{"id":"set1669398873962","function":"set","what":"@database.eventos.(@cookie.evento).marco","value":"m1.png"},{"id":"set1671397362802","function":"set","what":"@property.marcoactual","value":"config\/m1.png"}], options)
	}
	const image6OnClick = (options) => {
		run([{"id":"set1669398775699","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fondoapp","value":"f6.jpg"},{"id":"set1669398775699","function":"set","what":"@database.eventos.(@cookie.evento).fondoapp","value":"f6.jpg"},{"id":"set1671394393779","function":"set","what":"@property.fondoactual","value":"config\/f6.jpg"}], options)
	}
	const image1669398166672OnClick = (options) => {
		run([{"id":"set1669398760074","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fondoapp","value":"f5.jpg"},{"id":"set1669398760074","function":"set","what":"@database.eventos.(@cookie.evento).fondoapp","value":"f5.jpg"},{"id":"set1671394299877","function":"set","what":"@property.fondoactual","value":"config\/f5.jpg"}], options)
	}
	const image4OnClick = (options) => {
		run([{"id":"set1669400197710","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).marco","value":"m4.png"},{"id":"set1669400197710","function":"set","what":"@database.eventos.(@cookie.evento).marco","value":"m4.png"},{"id":"set1671394277768","function":"set","what":"@property.fondoactual","value":"config\/f4.jpg"}], options)
	}
	const image3OnClick = (options) => {
		run([{"id":"set1669398725940","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fondoapp","value":"f3.jpg"},{"id":"set1669398725940","function":"set","what":"@database.eventos.(@cookie.evento).fondoapp","value":"f3.jpg"},{"id":"set1671394263138","function":"set","what":"@property.fondoactual","value":"config\/f3.jpg"}], options)
	}
	const image2OnClick = (options) => {
		run([{"id":"set1669398699735","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fondoapp","value":"f2.jpg"},{"id":"set1669398699735","function":"set","what":"@database.eventos.(@cookie.evento).fondoapp","value":"f2.jpg"},{"id":"set1671394249912","function":"set","what":"@property.fondoactual","value":"config\/f2.jpg"}], options)
	}
	const image1OnClick = (options) => {
		run([{"id":"set1669398551223","function":"set","what":"@firebase.firestore.eventos.(@cookie.evento).fondoapp","value":"f1.jpg"},{"id":"set1669398672060","function":"set","what":"@database.eventos.(@cookie.evento).fondoapp","value":"f1.jpg"},{"id":"set1670694795590","function":"set","what":"@property.fondoactual","value":"config\/f1.jpg"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('configuracion.title')}</title>
      </Helmet>
      <AreaclienteWrapper>
          
			<Group
				id="groupconfig"
				direction="vertical"
				className="w-90 h-auto align-center position-relative" >
				<Group
					id="gruposelecionarfondo"
					direction="vertical"
					className="w-100 h-auto align-center position-relative" >
					<Text
						id="titulofondo"
						className="w-auto h-auto align-center position-relative p-20px f-20px" >
						{t('configuracion.titulofondo')}
					</Text>
					<Image
						id="image1671392902810"
						fallback={transp}
						src="@property.fondoactual"
						className="w-200px h-auto align-center position-relative" />
					<Text
						id="tituloactual"
						className="w-auto h-auto align-center position-relative p-20px f-20px" >
						{t('configuracion.tituloactual')}
					</Text>
					<Group
						id="grupoimagenesfon"
						direction="horizontal"
						className="w-100 h-auto align-center position-relative" >
						<Image
							id="image1"
							onClick={image1OnClick}
							src={f1}
							className="w-15 h-auto position-relative bg-white p-10px" />
						<Image
							id="image2"
							onClick={image2OnClick}
							src={f2}
							className="w-15 h-auto position-relative p-10px" />
						<Image
							id="image3"
							onClick={image3OnClick}
							src={f3}
							className="w-15 h-auto position-relative p-10px" />
						<Image
							id="image4"
							onClick={image4OnClick}
							src={f4}
							className="w-15 h-auto position-relative p-10px" />
						<Image
							id="image1669398166672"
							onClick={image1669398166672OnClick}
							src={f5}
							className="w-15 h-auto position-relative p-10px" />
						<Image
							id="image6"
							onClick={image6OnClick}
							src={f6}
							className="w-15 h-auto position-relative p-10px" />
					</Group>
					<Text
						id="titulomarco"
						className="w-auto h-auto align-center position-relative p-20px f-20px" >
						{t('configuracion.titulomarco')}
					</Text>
					<Group
						id="groupmarcoactual"
						direction="vertical"
						className="w-250px h-auto align-center position-relative" >
						<Image
							id="imageevento1671393159968"
							src={imgevento}
							className="w-100 h-auto position-relative p-10px" />
						<Image
							id="imagemarco1671393159968"
							fallback={m1}
							src="@property.marcoactual"
							className="w-100 h-auto position-absolute p-10px" />
					</Group>
					<Text
						id="titulomarcoactual"
						className="w-auto h-auto align-center position-relative p-20px f-20px" >
						{t('configuracion.titulomarcoactual')}
					</Text>
					<Group
						id="grupomarcos"
						direction="horizontal"
						className="w-100 h-auto align-center position-relative" >
						<Group
							id="groupmarco1"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Image
								id="imageevento"
								src={imgevento}
								className="w-100 h-auto position-relative p-10px" />
							<Image
								id="imagemarco1"
								onClick={imagemarco1OnClick}
								src={m1}
								className="w-100 h-auto position-absolute p-10px" />
						</Group>
						<Group
							id="groupmarco2"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Image
								id="imageevento1669399663313"
								src={imgevento}
								className="w-100 h-auto position-relative p-10px" />
							<Image
								id="imagemarco2"
								onClick={imagemarco2OnClick}
								src={m2}
								className="w-100 h-auto position-absolute p-10px" />
						</Group>
						<Group
							id="groupmarco3"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Image
								id="imageevento1669399722334"
								src={imgevento}
								className="w-100 h-auto position-relative p-10px" />
							<Image
								id="imagemarco3"
								onClick={imagemarco3OnClick}
								src={m3}
								className="w-100 h-auto position-absolute p-10px" />
						</Group>
						<Group
							id="groupmarco4"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Image
								id="imageevento1669399791542"
								src={imgevento}
								className="w-100 h-auto position-relative p-10px" />
							<Image
								id="imagenmarco4"
								onClick={imagenmarco4OnClick}
								src={m4}
								className="w-100 h-auto position-absolute p-10px" />
						</Group>
						<Group
							id="groupmarco5"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Image
								id="imageevento1669400017997"
								src={imgevento}
								className="w-100 h-auto position-relative p-10px" />
							<Image
								id="imagemarco"
								onClick={imagemarcoOnClick}
								src={m5}
								className="w-100 h-auto position-absolute p-10px" />
						</Group>
					</Group>
					<Text
						id="titulofuente"
						className="w-auto h-auto align-center position-relative p-20px f-20px" >
						{t('configuracion.titulofuente')}
					</Text>
					<Group
						id="grrupotipografia"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Group
							id="grrupoalex"
							onClick={grrupoalexOnClick}
							direction="vertical"
							className="w-20 h-auto position-relative bg-white" >
							<Text
								id="textalex"
								src="@database.eventos.(@cookie.evento).nombres"
								className="w-auto h-auto align-center position-relative f-30px ff-alexbrushregular" >
								{t('configuracion.textalex')}
							</Text>
							<Text
								id="textalex1670844102168"
								src="@property.font"
								className="w-auto h-auto align-center position-relative f-30px ff-alexbrushregular" >
								{t('configuracion.textalex1670844102168')}
							</Text>
						</Group>
						<Group
							id="grrupoblack"
							direction="vertical"
							className="w-20 h-auto position-relative" >
							<Text
								id="text1670676670537"
								src="@database.eventos.(@cookie.evento).nombres"
								className="w-auto h-auto align-center position-relative f-30px ff-blackjack" >
								{t('configuracion.text1670676670537')}
							</Text>
						</Group>
						<Group
							id="grrupogreat"
							direction="vertical"
							className="w-20 h-auto position-relative" >
							<Text
								id="textgreat"
								src="@database.eventos.(@cookie.evento).nombres"
								className="w-auto h-auto align-center position-relative f-30px ff-greatvibesregular" >
								{t('configuracion.textgreat')}
							</Text>
						</Group>
						<Group
							id="grrupohillda"
							direction="vertical"
							className="w-20 h-auto position-relative" >
							<Text
								id="texthillda"
								src="@database.eventos.(@cookie.evento).nombres"
								className="w-auto h-auto align-center position-relative f-30px ff-hillda" >
								{t('configuracion.texthillda')}
							</Text>
						</Group>
						<Group
							id="grrupowander"
							direction="vertical"
							className="w-20 h-auto position-relative" >
							<Text
								id="textwander"
								src="@database.eventos.(@cookie.evento).nombres"
								className="w-auto h-auto align-center position-relative f-30px ff-wanderlustlettersregular" >
								{t('configuracion.textwander')}
							</Text>
						</Group>
					</Group>
				</Group>
			</Group>
      </AreaclienteWrapper>
    </>
  );
};
