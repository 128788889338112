import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const GuardareventoDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const guardarevento_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"guardarevento"}], options)
	}

  return (
    
    
			<Modal id="guardarevento">
					<Modal.Body>
						<Group
							id="grupoguardarevento1670604346837"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1670604346837"
								className="w-100 h-auto position-relative p-20px mb-20px f-20px ff-renner" >
								{t('guardarevento.text1670604346837')}
							</Text>
							<Text
								id="text1670604346837"
								className="w-100 h-auto position-relative" >
								{t('guardarevento.text1670604346837')}
							</Text>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="guardarevento_buttons_positive"
							onClick={guardarevento_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('guardarevento.guardarevento_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default GuardareventoDialog;
