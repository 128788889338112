import React from 'react';

// Custom imports:
import ContactoDialog from './ContactoDialog';
import CreaeventoDialog from './CreaeventoDialog';
import GuardareventoDialog from './GuardareventoDialog';
import LoginDialog from './LoginDialog';
import NoadminDialog from './NoadminDialog';



export const Dialogs = () => {
  return (
    <>
      
			<ContactoDialog />
			<CreaeventoDialog />
			<GuardareventoDialog />
			<LoginDialog />
			<NoadminDialog />
      
    </>
  );
};

export default Dialogs;
