import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const CreaeventoDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const creaevento_buttons_positiveOnClick = (options) => {
		run([{"function":"open","what":"@firebase.firestore.eventos"},{"function":"popup","dialog":"existe","if":{"what":"@firebase.firestore.eventos.(@property.creaevento).existe","is":true}},{"function":"add","what":{"evento":"@property.creaevento","existe":true},"to":"@firebase.firestore.eventos","key":"@property.creaevento"},{"function":"add","what":{"wp":"@firebase.firestore.usuarios.(@firebase.user.id).codigowp","nombreevento":"@property.creaevento","time":"@app.timestamp"},"to":"@firebase.firestore.wp","if":{"what":"@firebase.firestore.usuarios.(@firebase.user.id).wp","is":true}},{"function":"add","what":{"evento":"@property.creaevento","existe":true},"to":"@database.eventos","key":"@property.creaevento"},{"function":"set","what":"@cookie.evento","value":"@property.creaevento"},{"function":"set","what":"@firebase.firestore.usuarios.(@cookie.userid).evento","value":"@property.creaevento"},{"function":"goto","view":"evento"},{"function":"hide","dialog":"creaevento"}], options)
	}

  return (
    
    
			<Modal id="creaevento">
					<Modal.Body>
						<Group
							id="gurpotodocrearevento1668778227830"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1668778227830"
								className="w-90 h-auto align-center position-relative f-20px ff-renner" >
								{t('creaevento.text1668778227830')}
							</Text>
							<Input.Text
								id="inputtext1668778227830"
								name="text"
								src="@property.creaevento"
							autoComplete="off"
								className="w-90 h-auto align-center position-relative my-20px" />
							<Text
								id="text1668778227830"
								className="w-90 h-auto align-center position-relative ff-renner" >
								{t('creaevento.text1668778227830')}
							</Text>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="creaevento_buttons_positive"
							onClick={creaevento_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('creaevento.creaevento_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default CreaeventoDialog;
